import { Home } from 'views/Home';
import { Landing } from 'views/Landing';
import { Inventory } from 'views/Inventory';
import { Order } from 'views/Order';
import { WizardiaLanding } from 'views/Wizardia';

const privateRoute = {
  home: {
    path: '/',
    component: WizardiaLanding,
  },
  projectLanding: {
    path: '/:project',
    urlBoomi: '/Super-BOOMi',
    urlMcgee: '/americanmcgee',
    urlAnimalia: '/animalia',
    urlDinoX: '/dinoX',
    urlBitHotel: '/bithotel',
    urlRitestream: '/ritestream',
    component: Landing,
  },
  projectInventory: {
    path: '/:project/inventory',
    urlBoomi: '/Super-BOOMi/inventory',
    component: Inventory,
    requiredLogin: true,
  },
  projectOrder: {
    path: '/:project/order-history',
    urlBoomi: '/Super-BOOMi/order-history',
    urlMcgee: '/americanmcgee/order-history',
    urlAnimalia: '/animalia/order-history',
    urlDinoX: '/dinoX/order-history',
    urlBitHotel: '/bithotel/order-history',
    urlRitestream: '/ritestream/order-history',
    component: Order,
    requiredLogin: true,
  },
};

export default privateRoute;
