import React from 'react';
import { Typography } from '@mui/material';
import { CountdownFlip } from 'components';
import { CampTimer } from 'utils/campaign';

const BoomiHeader = () => {
  const [{ isStarted, isFinished, countdown }] = React.useState(() =>
    CampTimer({
      saleStart: '2021-11-26T13:00:00.000Z',
      saleEnd: '2021-11-27T13:00:00.000Z',
    }),
  );

  return (
    <div className='flex flex-col items-center'>
      <img src={require('assets/projects/boomi/logo-spores-boomi.svg').default} className='mb-10 mx-3' />
      <div className='flex items-center mb-3'>
        <div className='w-10 sm:w-32 bg-white' style={{ height: 1 }} />
        <Typography className='text-2xl ml-3 mr-2' align='center' letterSpacing={8}>
          LOOT BOX
        </Typography>
        <div className='w-10 sm:w-32 bg-white' style={{ height: 1 }} />
      </div>
      <Typography variant='h1' align='center' className='text-3xl sm:text-5xl md:text-7xl'>
        SUPER BOOMi PRESALE
      </Typography>

      <div className='box-border my-6'>
        <Typography variant='subtitle1' align='center' className='mb-4' letterSpacing={2}>
          {isFinished ? 'SALE HAS ENDED' : isStarted ? 'SALE ENDS IN' : 'SALE STARTS IN'}
        </Typography>
        <CountdownFlip {...countdown} />
      </div>
    </div>
  );
};

export default BoomiHeader;
