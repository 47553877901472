import { Link } from 'react-router-dom';
import { AppBar, Link as MuiLink, Toolbar, Typography } from '@mui/material';
import { privateRoute } from 'routes';

const Footer = () => {
  return (
    <AppBar position='sticky' elevation={0} style={{ backgroundColor: '#232323' }}>
      <Toolbar>
        <Link to={privateRoute.home.path}>
          <img src={require('assets/icons/logo-spores-invert.svg').default} className='h-8 sm:h-10' />
        </Link>

        <div className='flex-1 flex justify-end text-gray-200'>
          <MuiLink href='https://marketplace.spores.app' className='text-gray-200 hover:text-white mr-6'>
            Marketplace
          </MuiLink>
          <MuiLink href='https://republique.spores.app/' className='text-gray-200 hover:text-white mr-6'>
            République
          </MuiLink>
          <MuiLink href='https://games.spores.app' className='text-gray-200 hover:text-white mr-6'>
            Games
          </MuiLink>

          <Typography className='hidden md:inline'>
            Contact Us:{' '}
            <MuiLink href='mailto:customersupport@spores.app' className='text-gray-200 hover:text-white'>
              customersupport@spores.app
            </MuiLink>
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
