import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { CountdownFlip } from 'components';
import { CampTimer } from 'utils/campaign';

const BoomiHeader = () => {
  const { sale_start } = useSelector(({ profile }) => profile);
  const [{ isStarted, isFinished, countdown }, setTimer] = React.useState(CampTimer);

  React.useEffect(() => {
    setTimer(() => CampTimer({ saleStart: sale_start }));
  }, [sale_start]);

  return (
    <div className='flex flex-col items-start max-w-2xl my-16'>
      <img src={require('assets/projects/americanmcgee/logo-primary.png').default} className='mb-10 mx-3' />
      <div className='flex items-center mb-3'>
      </div>
      <Typography variant='h1' align='left' className='text-6xl sm:text-5xl md:text-7xl'>
        Plushie Dreadfuls PRESALE
      </Typography>

      <div className='box-border my-6 border-transparent' style={{ background: '#0F1212'}}>
        <Typography variant='subtitle1' align='center' className='mb-4' letterSpacing={2}>
          {isFinished ? 'SALE HAS ENDED' : isStarted ? 'SALE ENDS IN' : 'SALE STARTS IN'}
        </Typography>
        <CountdownFlip {...countdown} />
      </div>
    </div>
  );
};

export default BoomiHeader;
