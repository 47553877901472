import { Redirect, Route, Switch } from 'react-router';
import { privateRoute } from 'routes';
import { BoomiLanding } from 'views/Boomi';
import { McGeeLanding } from 'views/McGee';
import { AnimaliaLanding } from 'views/Animalia';
import { DinoXLanding } from 'views/DinoX';
import { BitHotelLanding } from 'views/BitHotel';
import { RitestreamLanding } from 'views/Ritestream';

const Landing = () => {
  return (
    <>
      <Switch>
        <Route path={privateRoute.projectLanding.urlBoomi} component={BoomiLanding} />
        <Route path={privateRoute.projectLanding.urlMcgee} component={McGeeLanding} />
        <Route path={privateRoute.projectLanding.urlAnimalia} component={AnimaliaLanding} />
        <Route path={privateRoute.projectLanding.urlDinoX} component={DinoXLanding} />
        <Route path={privateRoute.projectLanding.urlBitHotel} component={BitHotelLanding} />
        <Route path={privateRoute.projectLanding.urlRitestream} component={RitestreamLanding} />
        <Redirect from='/' to={privateRoute.home.path} />
      </Switch>
      <Redirect from='/dinoX' to={privateRoute.projectLanding.urlRitestream} />
    </>
  );
};

export default Landing;
