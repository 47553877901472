import { ContactPageSharp, CreateOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { DialogActions, FormControl, TextField, Typography } from '@mui/material';
import { CountdownFlip, DesignButton } from 'components';
import { erc20Contract, web3 } from 'contracts/contract';
import { ADDRESS_RECEIVER } from 'env';
import { DateTime } from 'luxon';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { marketService } from 'services/market';
import { storageService } from 'services/storage';
import { formatNumber } from 'utils/common';
import { isValidEmail, isValidTransID } from 'utils/validator';

const PopupCart = ({ onClose, onSuccess, onRefresh }) => {
  const { address, isVip } = useSelector(({ profile }) => profile);
  const { control, handleSubmit, setValue } = useForm();
  const [countdown] = React.useState(() => {
    const { createdAt } = storageService.getOrder();
    return DateTime.fromISO(createdAt).plus({ minutes: 10 }).diff(DateTime.now(), ['minutes', 'seconds']).toObject();
  });
  const [{ currentPrice, totalDiscount, totalPrice, discount, alloPerX, ...order }] = React.useState(storageService.getOrder);
  const currentSymbol = currentPrice.payment_token.symbol;

  const { mutate: submitOrder, isLoading } = useMutation(marketService.submitOrder, {
    onSuccess: () => {
      onClose();
      onSuccess();
      onRefresh();
      storageService.clearOrder();
    },
  });

  const handleClickCancel = () => {
    onClose();
    storageService.clearOrder();
    marketService.cancelOrder({ order_id: order.orderId }).finally(onRefresh);
  };

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      submitOrder({
        order_id: order.orderId,
        transaction_hash: values.transactionHash,
        email: values.email,
      });
    })();
  };

  const transfer = React.useCallback(async () => {
    try {
      const payment_token = currentPrice?.payment_token;
      if (payment_token.address) {
        const price = (totalPrice.toFixed(0) * 10 ** (payment_token.decimal)).toLocaleString('fullwide', {
          useGrouping: false,
        });
        const tx = erc20Contract(payment_token.address).methods.transfer(ADDRESS_RECEIVER, price)
        const transfer = await tx.send({ from: address });
        
        setValue('transactionHash', transfer.transactionHash);
      } else {
        const transfer = await web3.eth.sendTransaction({
          from: address,
          to: ADDRESS_RECEIVER,
          value: web3.utils.toWei(String(totalPrice), 'ether'),
        });
        setValue('transactionHash', transfer.transactionHash);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }, [setValue, address, totalPrice, currentPrice]);

  React.useEffect(() => {
    transfer();
  }, [transfer]);

  return (
    <div className='box-border text-white p-10 overflow-x-hidden'>
      <Typography
        variant='h3'
        className='flex items-center justify-center'
        style={{
          width: 720,
          height: 60,
          backgroundColor: '#4E4462',
          margin: '-40px -40px 24px',
          borderBottom: '2px solid #624266',
          borderTopLeftRadius: 14,
          borderTopRightRadius: 14,
        }}
      >
        CHECKOUT
      </Typography>

      <div className='flex flex-col items-center'>
        <Typography variant='subtitle1' className='mb-2'>
          YOUR ORDER WILL EXPIRE IN
        </Typography>
        <div className='mb-6'>
          <CountdownFlip name='cart' {...countdown} />
        </div>
      </div>

      <div className='rounded-lg py-4 px-6' style={{ backgroundColor: '#4E4462' }}>
        {order.products.map((item, index) => {
          return (
            <div key={index} className='flex items-center justify-between py-1.5'>
              <div className='flex justify-between w-80'>
                <Typography variant='subtitle1'>RITE</Typography>
                <Typography variant='subtitle1'></Typography>
              </div>
              <Typography variant='subtitle1'>
                {formatNumber(totalPrice)} {currentSymbol}
              </Typography>
            </div>
          );
        })}
        {/*show discount and total price */}
        {/* <div className='flex items-center justify-between py-1.5 mb-1'>
          <div className='flex justify-between w-80'>
            <Typography variant='subtitle1'>Discount</Typography>
            <Typography variant='subtitle1'>{totalDiscount ? discount * 100 + '%' : ''}</Typography>
          </div>
          <Typography variant='subtitle1'>
            {formatNumber(totalDiscount)} {currentSymbol}
          </Typography>
        </div>

        <div style={{ height: 1, background: 'rgba(255, 255, 255, 0.2)' }} />
        <div className='flex items-center justify-between py-1.5'>
          <Typography variant='subtitle1'>Total</Typography>
          <Typography variant='subtitle1' color='primary'>
            {formatNumber(totalPrice)} {currentSymbol}
          </Typography>
        </div> */}
      </div>

      <div className='rounded-lg mt-8 py-4 px-6' style={{ backgroundColor: '#4E4462' }}>
        <Typography variant='body2'>
          • Please select Binance Smart Chain and proceed your payment to the following address:
        </Typography>
        <Typography variant='h4' color='primary' className='flex justify-center my-2'>
          {ADDRESS_RECEIVER}
          <img
            src={require('assets/icons/icon-copy.svg').default}
            className='cursor-pointer ml-2'
            title='Copy'
            onClick={() => navigator.clipboard.writeText(ADDRESS_RECEIVER)}
          />
        </Typography>
        <Typography variant='body2'>• Complete your Order within the allowed time frame.</Typography>
        <Typography variant='body2'>• Upon successful payment, click Submit.</Typography>
      </div>

      <Controller
        name='transactionHash'
        defaultValue=''
        control={control}
        rules={{ validate: { pattern: (value) => isValidTransID(value) || 'Transaction ID is not valid' } }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth className='mt-6'>
            <label>
              Transaction ID <span className='text-red-500'>*</span>
            </label>
            <TextField
              style={{
                background: '#40394F',
                border: '1px solid #5E6484',
                boxSizing: 'border-box',
                borderRadius: '4px',
              }}
              {...field}
              InputProps={{ endAdornment: <CreateOutlined className='text-gray-400' /> }}
              error={invalid}
              helperText={error?.message}
            />
          </FormControl>
        )}
      />
      {!isVip && (
        <Controller
          name='email'
          defaultValue=''
          control={control}
          rules={{ validate: { pattern: (value) => isValidEmail(value) || 'Email is not valid' } }}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl fullWidth className='mt-6'>
              <label>
                Email <span className='text-red-500'>*</span>
              </label>
              <TextField
                style={{
                  background: '#40394F',
                  border: '1px solid #5E6484',
                  boxSizing: 'border-box',
                  borderRadius: '4px',
                }}
                {...field}
                InputProps={{ endAdornment: <CreateOutlined className='text-gray-400' /> }}
                error={invalid}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />
      )}

      <DialogActions className='flex justify-end mt-6'>
        <LoadingButton size='large' color='inherit' className='px-6' disabled={isLoading} onClick={handleClickCancel}>
          Cancel
        </LoadingButton>
        <DesignButton size={160} loading={isLoading} onClick={handleClickSubmit}>
          SUBMIT
        </DesignButton>
      </DialogActions>
    </div>
  );
};

export default PopupCart;
