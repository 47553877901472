import React from 'react';
import { useHistory } from 'react-router';
import { Button, Container, Hidden, Grid, Typography } from '@mui/material';
import { DesignButton } from 'components';
import { connectWallet } from 'reducers/profileAction';
import { CampTimer } from 'utils/campaign';
import { BoomiBanner, BoomiHeader } from 'views/Boomi/components';
import { privateRoute } from 'routes';

const Rarity = ({ type }) => {
  const color = () => {
    if (type === 'Common') return '#D8D8D8';
    if (type === 'Rare') return '#00E742';
    if (type === 'Super Rare') return '#61C4F7';
    if (type === 'Epic') return '#FC51FF';
    if (type === 'Legendary') return '#FFD148';
  };
  return (
    <div className='inline-flex rounded-full border h-7 px-3' style={{ borderColor: color(), color: color() }}>
      {type}
    </div>
  );
};

const BoomiLanding = () => {
  const history = useHistory();

  const [{ isStarted, isFinished }] = React.useState(() =>
    CampTimer({
      saleStart: '2021-11-26T13:00:00.000Z',
      saleEnd: '2021-11-27T13:00:00.000Z',
    }),
  );

  const handleClickGo = () => {
    if (isStarted && !isFinished) {
      connectWallet(() => {
        history.push(privateRoute.projectInventory.urlBoomi);
      });
    }
  };

  return (
    <div className='text-white'>
      <div
        style={{
          maxHeight: 1200,
          background: `url(${require('assets/projects/boomi/background-landing.png').default}) no-repeat`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      >
        <Container className='flex flex-col items-center py-12'>
          <BoomiHeader />

          <DesignButton type={isFinished ? 'error' : 'primary'} onClick={handleClickGo}>
            {isFinished ? 'SALE HAS ENDED' : isStarted ? 'GET YOUR BOX NOW!' : 'COMING SOON'}
          </DesignButton>
          <div className='sm:-my-20'>
            <img src={require('assets/projects/boomi/banner-bears.png').default} />
          </div>
        </Container>
      </div>

      <div style={{ backgroundColor: '#211E25' }}>
        <Container className='py-12'>
          <div className='sm:-mt-32'>
            <BoomiBanner />
          </div>
        </Container>

        <Container className='flex flex-col items-center py-12'>
          <Typography variant='h1' align='center' className='text-4xl sm:text-5xl mb-3'>
            Spores x BOOMi Drops
          </Typography>
          <Typography align='center' className='mb-10'>
            Spores x BOOMi is our first collection where you can purchase{' '}
            <Typography component='span' variant='subtitle1' color='primary'>
              loot boxes
            </Typography>
            <br />
            to score 8 types of characters with different rarity variations.
          </Typography>

          <Button
            size='large'
            color='inherit'
            className='w-80 rounded-full'
            style={{ background: 'linear-gradient(90deg, #0099DB 0%, #6C55FF 50%, #FF42CA 100%)' }}
          >
            ‘Rookie’ Characters
          </Button>
          <Grid container spacing={3} justifyContent='center' className='mt-4 mb-16'>
            {[
              {
                image: require('assets/projects/boomi/hero-Bug.png').default,
                video: 'https://video.wixstatic.com/video/15fdb7_dc749dd5f9af4e24a9365fc75445dde2/720p/mp4/file.mp4',
                hero: 'Mega Bug (Villain)',
                rarity: 'Common',
                describe: `Mega Bug is the glitch inside BOOMi's virtual game world.\nHe wants to take over all the games and toys that BOOMi and his friends like to play with, and eventually Mega Bug wants to control the entire internet!\nBOOMi has to "Beat the Bug or it's GAME OVER"!`,
              },
              {
                image: require('assets/projects/boomi/hero-BOOMi.png').default,
                video: 'https://video.wixstatic.com/video/15fdb7_db41d9ec07d54586b1d5a742a86b468e/720p/mp4/file.mp4',
                hero: 'BOOMi Superhero',
                rarity: 'Rare',
                describe: `BOOMi is the first bear in the world who knows computer programming.\nAs an inventor, he has created a virtual reality game universe where he and his friends can embark on fantastic adventures and roleplay as different characters.`,
              },
              {
                image: require('assets/projects/boomi/hero-Mac.png').default,
                video: 'https://video.wixstatic.com/video/15fdb7_0d027811faaa47dcac72546fc7e03f56/720p/mp4/file.mp4',
                hero: 'Mac Superhero',
                rarity: 'Rare',
                describe: `If you're looking for fun, just keep an eye out for BOOMi's silly zebra friend, Mac!\nWhen BOOMi and friends are in the virtual reality game world, Mac always brings lots of laughs along for the adventure.`,
              },
              {
                image: require('assets/projects/boomi/hero-Mia.png').default,
                video: 'https://video.wixstatic.com/video/15fdb7_31c532cf00c94f7a8ff3b6d93c9ddc28/720p/mp4/file.mp4',
                hero: 'Mia Superhero',
                rarity: 'Rare',
                describe: `BOOMi's friend Mia is a smart and athletic cat who loves dancing and sports.\nShe's always willing to jump into action to help her friends on their exciting adventures.`,
              },
              {
                image: require('assets/projects/boomi/hero-Bibop.png').default,
                video: 'https://video.wixstatic.com/video/15fdb7_1342f17bdc154955bad036765272669f/720p/mp4/file.mp4',
                hero: 'Bibop Superhero',
                rarity: 'Rare',
                describe: `Bibop is BOOMi's super intelligent puppy who is every kid's dream pet!\nBibop can fly with his rocket paws, retrieve Star Rewards and grow super big to protect BOOMi and his friends. Bibop's different powers come in handy to help BOOMi, Mia and Mac in their exciting adventures.`,
              },
            ].map((item, index) => (
              <Grid item key={index} lg={6} md={8} xs={12}>
                <div className='box-border flex flex-col md:flex-row md:items-start h-full'>
                  <Hidden smDown>
                    <video src={item.video} className='w-60 mx-auto mb-3 md:mb-0 md:ml-0 md:mr-6' autoPlay loop muted />
                  </Hidden>
                  <Hidden smUp>
                    <img src={item.image} className='w-60 mx-auto mb-3 md:mb-0 md:ml-0 md:mr-6' />
                  </Hidden>
                  <div>
                    <div className='text-center md:text-left'>
                      <Typography variant='h3'>{item.hero}</Typography>
                      <div className='my-2'>
                        <Rarity type={item.rarity} />
                      </div>
                    </div>
                    <Typography color='silver' whiteSpace='pre-line' align='justify'>
                      {item.describe}
                    </Typography>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>

          <Button
            size='large'
            color='inherit'
            className='w-80 rounded-full'
            style={{ background: 'linear-gradient(90deg, #0099DB 0%, #6C55FF 50%, #FF42CA 100%)' }}
          >
            Limited Edition Characters
          </Button>
          <Grid container spacing={3} justifyContent='center' className='mt-4 mb-16'>
            {[
              {
                image: require('assets/projects/boomi/hero-BOOMi-A.png').default,
                video: 'https://video.wixstatic.com/video/15fdb7_d0243e06f00147b7aa90b2a86c685608/720p/mp4/file.mp4',
                hero: 'BOOMi Astronaut',
                rarity: 'Super Rare',
                describe: `BOOMi is a big science fiction fan, so he's embarked on many adventures in space!\nThis Super Rare piece features BOOMi leaping on the moon in his state-of-the-art spacesuit, bounding along the infinite horizon of outer space.`,
              },
              {
                image: require('assets/projects/boomi/hero-BOOMi-B.png').default,
                video: 'https://video.wixstatic.com/video/15fdb7_19e598fa966f43aeaa8f3e1c93447d6b/720p/mp4/file.mp4',
                hero: 'BOOMi Gold',
                rarity: 'Epic',
                describe: `Every hero is worth his weight in gold, and Super BOOMi has proven himself golden starring in one of the most successful original animated series of its kind launched in recent years. With more adventures on the way, BOOMi is shining brighter than ever!`,
              },
              {
                image: require('assets/projects/boomi/hero-BOOMi-C.png').default,
                video: 'https://video.wixstatic.com/video/15fdb7_c252467704aa46d6a400b1b26bb38823/720p/mp4/file.mp4',
                hero: 'BOOMi Rainbow',
                rarity: 'Legendary',
                describe: `An icon of youth, energy and vibrant imagination, Super BOOMi Rainbow embodies these powerful attributes in this most rare and never-before-seen interpretation of the cartoon star. This Legendary NFT is the first ever appearance of Rainbow BOOMi, which will be featured in an episode of the award-winning TV series in 2022. Unlock this Legendary version to collect Rainbow BOOMi before millions of fans see its debut in the show!`,
              },
            ].map((item, index) => (
              <Grid item key={index} lg={6} md={8} xs={12}>
                <div className='box-border flex flex-col md:flex-row md:items-start h-full'>
                  <Hidden smDown>
                    <video src={item.video} className='w-60 mx-auto mb-3 md:mb-0 md:ml-0 md:mr-6' autoPlay loop muted />
                  </Hidden>
                  <Hidden smUp>
                    <img src={item.image} className='w-60 mx-auto mb-3 md:mb-0 md:ml-0 md:mr-6' />
                  </Hidden>
                  <div>
                    <div className='text-center md:text-left'>
                      <Typography variant='h3'>{item.hero}</Typography>
                      <div className='my-2'>
                        <Rarity type={item.rarity} />
                      </div>
                    </div>
                    <Typography color='silver' whiteSpace='pre-line' align='justify'>
                      {item.describe}
                    </Typography>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Container>

        <Container className='py-12'>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12} className='flex justify-center'>
              <img src={require('assets/projects/boomi/who-is-artist.png').default} className='rounded-2xl' />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant='subtitle1' letterSpacing={2}>
                ABOUT THE ARTIST
              </Typography>
              <Typography variant='h1' color='primary' className='mb-4'>
                Trevor Lai
              </Typography>
              <Typography paragraph color='silver' align='justify'>
                Trevor Lai is an award-winning author, illustrator and entrepreneur who has published more than 30 books
                for readers of all ages. He is also the founder and CEO of UP Studios, which is dedicated to creating,
                developing and producing content and merchandise based on original properties for kids and families.
              </Typography>
              <Typography paragraph color='silver' align='justify'>
                “As a lover of art, comics, and collectibles since childhood, it’s always been a dream of mine to create
                works that fellow collectors will love and cherish. In the 10 years since founding Up Studios, I have
                watched BOOMi grow from a sketch on a napkin into an international franchise with millions of fans
                around the globe. The premiere collection of Super BOOMi NFTs for the Spores Network is just the first
                part of our plan to create digital collectibles, unlockable treasures and game experiences built on a
                rich story universe of over 156 episodes and counting!” — Trevor Lai, Up Studios CEO & Creative
                Director.
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <div className='h-20' />
      </div>
    </div>
  );
};

export default BoomiLanding;
