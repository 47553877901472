import { client } from './axios';

const getQuota = () => client.get('/userlevel');
const fetchProducts = (params) => client.get('/products', { params });
const fetchOrders = (params) => client.get('/order/history', { params });
const fetchCampaignsTime = () => client.get('/campaigntime');
const checkout = (body) => client.post(`/order`, body);
const submitOrder = ({ order_id, ...body }) => client.post(`/order/${order_id}/submit`, body);
const cancelOrder = ({ order_id }) => client.delete(`/order/${order_id}`);
const getMaxAmount = () => client.get('/max-amount');

export const marketService = {
  getQuota,
  fetchProducts,
  fetchOrders,
  fetchCampaignsTime,
  checkout,
  submitOrder,
  cancelOrder,
  getMaxAmount,
};
