import { Grid, Typography, Link as MuiLink } from '@mui/material';
import React from 'react';
import { BitHotelLeftCard } from 'views/BitHotel/components';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const BitHotelLanding = () => {
  const [item, setItem] = React.useState(0);
  const handleClickCard = (name) => {
    setItem(name);
  };
  const BundleTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#48484A',
        width: '800px',
      },
    }),
  );
  return (
    <div className='text-white'>
      <div
        className='p-4 lg:p-20 flex flex-col items-center justify-center'
        style={{
          background: `url(${require('assets/projects/animalia/background-landing.png').default})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          paddingBottom: 180,
        }}
      >
        <Grid
          container
          spacing={4}
          className='flex justify-center items-start'
          style={{
            maxWidth: '1500px',
          }}
        >
          <Grid item xs={12} md={12} lg={3.5} xl={3.5} className='xl:sticky top-16'>
            <div
              style={{
                backgroundColor: '#2C2C2E',
                borderRadius: '8px',
              }}
              className='p-4 md:p-8 xl:max-w-lg lg:w-full mx-auto'
            >
              <BitHotelLeftCard nameItem={item} />
            </div>
          </Grid>
          <Grid item md={12} lg={8} xl={8.5}>
            <div
              style={{
                backgroundColor: '#2C2C2E',
                borderRadius: '8px',
              }}
              className='p-4 md:p-8 justify-between max-w-screen-xl'
            >
              <img className='mb-8 w-full' src={require('assets/projects/bithotel/LandingTop.png').default} />
              <div className='mb-4'>
                Bit Hotel is a Social-first Play 2 Earn NFT Gaming Metaverse, in which users can compete to earn Bit
                Hotel tokens and acquire native NFTs. These NFTs have in-game usability and consist of characters, hotel
                rooms, furniture and other artifacts that have their own perks.
              </div>
              <div className='flex my-4'>
                <MuiLink
                  href='https://bithotel.io/'
                  className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
                  title='Official Website'
                >
                  <img
                    className='fill-current text-green-600 '
                    src={require('assets/icons/media/website.svg').default}
                  />
                </MuiLink>
                <MuiLink
                  href='https://www.twitter.com/playbithotel'
                  className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
                  title='Twitter'
                >
                  <img src={require('assets/icons/media/twitter.svg').default} />
                </MuiLink>
                <MuiLink
                  href='https://t.me/bithotelcommunity'
                  className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
                  title='Telegram'
                >
                  <img src={require('assets/icons/media/telegram.svg').default} />
                </MuiLink>
                <MuiLink
                  href='https://discord.gg/RFFZNwxY9n'
                  className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
                  title='Discord'
                >
                  <img src={require('assets/icons/media/discord.svg').default} />
                </MuiLink>
                <MuiLink
                  href='https://medium.com/@bithotelnftgame'
                  className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
                  title='Medium'
                >
                  <img src={require('assets/icons/media/medium.svg').default} />
                </MuiLink>
                <MuiLink
                  href='https://www.reddit.com/r/bithotelcommunity'
                  className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
                  title='Reddit'
                >
                  <img src={require('assets/icons/media/reddit.svg').default} />
                </MuiLink>
                <MuiLink
                  href='https://www.youtube.com/channel/UCYnz55RptB22U5rRHNxz1Fg'
                  className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
                  title='Youtube'
                >
                  <img src={require('assets/icons/media/youtube.svg').default} />
                </MuiLink>
                <MuiLink
                  href='https://instagram.com/bit_hotel_official_game?utm_medium=copy_link'
                  className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
                  title='Instagram'
                >
                  <img src={require('assets/icons/media/instagram.svg').default} />
                </MuiLink>
              </div>
              <div className='flex 2xl:flex-row flex-col 2xl:space-y-0 space-y-2  2xl:justify-between items-center mb-8'>
                <div>
                  <table style={{ backgroundColor: 'transparent' }}>
                    <tbody>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #8E8E93' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/dinoX/icons/tableIcon1.svg').default}
                            />
                            Network
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #8E8E93' }}>
                          Binance Smart Chain (BSC)
                        </td>
                      </tr>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #8E8E93' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/dinoX/icons/tableIcon2.svg').default}
                            />
                            Start time
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #8E8E93' }}>
                          15 February 2022 13:00 UTC
                        </td>
                      </tr>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #8E8E93' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/dinoX/icons/tableIcon3.svg').default}
                            />
                            End time
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #8E8E93' }}>
                          17 February 2022 13:00 UTC
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table style={{ backgroundColor: '#transparent' }}>
                    <tbody>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #8E8E93' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/dinoX/icons/tableIcon4.svg').default}
                            />
                            Quantity
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #8E8E93' }}>
                          96
                        </td>
                      </tr>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #8E8E93' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/dinoX/icons/tableIcon5.svg').default}
                            />
                            NFT type
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #8E8E93' }}>
                          ERC-721
                        </td>
                      </tr>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #8E8E93' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/dinoX/icons/tableIcon6.svg').default}
                            />
                            Distribution network
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #8E8E93' }}>
                          Binance Smart Chain (BSC)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <Grid className='flex flex-col'>
                <Typography variant='h1' className='self-center mb-6'>
                  Inventory
                </Typography>
                <div className='font-bold' style={{ fontSize: '24px' }}>
                  Bit Hotel Rooms
                </div>
                <Grid container spacing={2} justifyContent='center' className='mt-1 mb-16'>
                  {[
                    {
                      img: require('assets/projects/bithotel/inventory/Two Person Room.png').default,
                      name: 'Two Person Room',
                      rarity: 'Common',
                      rarityImg: require('assets/projects/bithotel/common.png').default,
                      price: '500 BUSD',
                      remain: '24 items',
                      description:
                        'A comfortable entry-level room in the Bit Hotel! The Two Person Room is a minimalistic and clean room with just enough space to have a few friends over and have some fun. Perfect for lovebirds sharing their first flat together, too.',
                    },
                    {
                      img: require('assets/projects/bithotel/inventory/Family Room.png').default,
                      name: 'Family Room',
                      rarity: 'Common',
                      rarityImg: require('assets/projects/bithotel/common.png').default,
                      price: '600 BUSD',
                      remain: '24 items',
                      description:
                        'If you are looking for a larger room, with beautiful marble accents, this is definitely something for you. The Family Room is an entry-level hotel room, but with more than enough space to accommodate your friends. The room is most commonly used by groups of friends that want to host a small party. Just add a jukebox, disco ball and colourful dance floor and you are good to go.',
                    },
                    {
                      img: require('assets/projects/bithotel/inventory/Starter Studio.png').default,
                      name: 'Starter Studio',
                      rarity: 'Uncommon',
                      rarityImg: require('assets/projects/bithotel/uncommon.png').default,
                      price: '1,000 BUSD',
                      remain: '16 items',
                      description:
                        'The Starter Studio is what most hotel guests acquire when they start to do well in-game. All leaderboard legends had one and worked their way up. The mezzanine ensures you have a cool extra space for your bed (or hammock!)',
                    },
                    {
                      img: require('assets/projects/bithotel/inventory/Comfortable Studio.png').default,
                      name: 'Comfortable Studio',
                      rarity: 'Uncommon',
                      rarityImg: require('assets/projects/bithotel/uncommon.png').default,
                      price: '2,000 BUSD',
                      remain: '12 items',
                      description:
                        'Our L-shaped Comfortable Studio might be something for you, is for those looking for a little more space and comfort. It has 50 tiles to make it the coziest studio you could imagine. This Comfortable Studio has more than enough room for a number of cool pieces of furniture, and to host mini-games.',
                    },
                    {
                      img: require('assets/projects/bithotel/inventory/Extravagant Studio.png').default,
                      rarity: 'Uncommon',
                      rarityImg: require('assets/projects/bithotel/uncommon.png').default,
                      name: 'Extravagant Studio',
                      price: '3,300 BUSD',
                      remain: '20 items',
                      description:
                        'This one’s for the extravaganzas among us that don’t want to splash all their money on upscale condos. Luxurious look and feel, compact but spacious. The Extravagant Studio is our entry option to upscale real estate in the Bit Hotel ecosystem. It’s great comfort at a fraction of the cost.',
                    },
                  ].map((item, index) => (
                    <Grid item key={index} lg={6} md={8} xs={12}>
                      <div
                        className='p-5 flex flex-col justify-center items-center h-full hover:bg-opacity-40 hover:bg-gray-500'
                        style={{ borderRadius: '8px', backgroundColor: '#3A3A3C', minHeight: '500px' }}
                        onClick={() => handleClickCard(item.name)}
                      >
                        <div
                          className='font-bold mt-2'
                          style={{
                            fontSize: '18px',
                          }}
                        >
                          {item.name}
                        </div>
                        <div
                          className='mb-2'
                          style={{
                            fontSize: '14px',
                            color: 'rgba(255, 255, 255, 0.7)',
                          }}
                        >
                          {item.rarity}
                        </div>
                        <img src={item.rarityImg} />
                        <div>
                          <img src={item.img} className='w-full my-4' style={{ width: '72px' }} />
                        </div>
                        <div className='flex-1'>
                          <div
                            className='flex justify-between items-center md:text-left'
                            style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}
                          >
                            <div
                              className='font-bold text-transparent bg-clip-text'
                              style={{
                                fontSize: '24px',
                                background: '-webkit-linear-gradient(225deg, #FFEF5E 0%, #F7936F 100%)',
                              }}
                            >
                              {item.price}
                            </div>
                            <div
                              style={{
                                fontSize: '16px',
                                color: 'rgba(255, 255, 255, 0.7)',
                              }}
                            >
                              {item.remain}
                            </div>
                          </div>
                          <Typography
                            className='mt-4'
                            align='justify'
                            style={{
                              fontSize: '16px',
                              color: 'rgba(255, 255, 255, 0.7)',
                            }}
                          >
                            {item.description}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
                <Typography variant='h1' className='self-center text-center mb-8'>
                  Project information
                </Typography>
                <video
                  src={
                    'https://spores-marketplace-storage.s3.ap-southeast-1.amazonaws.com/ab670ce2-e3b5-4b60-9278-9f74553b6b90'
                  }
                  muted
                  autoPlay
                  loop
                  controls
                />
                <Typography
                  className='self-center mt-6 font-bold mb-4 max-w-lg leading-10'
                  style={{
                    fontSize: '32px',
                    textAlign: 'center',
                  }}
                >
                  What are Bit Hotel rooms and what do they look like?
                </Typography>
                <div className='mb-4'>
                  Differently from what you might expect from a Hotel, the rooms in the Bit Hotel are not for rent, but
                  actually for sale. In this way we can make Bit Hotel decentralized and thus owned by many who can
                  exert governance (voting rights) in-game.
                </div>
                <div>
                  A room in Bit Hotel is a digital piece of real estate in the Bit Hotel Metaverse. Even though some
                  rooms have the same layout, each room is a unique token on the Binance smartchain. The hotel has 5
                  different types of floor-plans and over 50 floors, based on the room layout there are over 10 options
                  to choosefrom at the time of writing. One section of the hotel consists of 5 floors, every floor has a
                  different distribution of room designs and sizes. The hotel is divided in 10 sections of different
                  rarity, hence 50 floors.
                </div>
                <Typography
                  className='self-center mt-6 font-bold mb-4'
                  style={{
                    fontSize: '32px',
                  }}
                >
                  Play to Earn Model
                </Typography>
                <div className='text-lg font-semibold'>Play 2 Earn Room Mechanics</div>
                <div className='mb-4'>
                  If you own a room in the Hotel, you’ll be able to store, use and exhibit your furniture. There’s a
                  marketplace full of NFTs, furniture and decorations for you to choose from when the game launches.
                  Furniture enables you or your guests / renters to earn and win tokens. You’ll also be able to design
                  and create your own mini-games and offer prizes to other guests in the hotel. Also…
                </div>
                <div className='text-lg font-semibold'>Renting out your Rooms</div>
                <div className='mb-4'>
                  Not going to be around for a while? Put your room on our rental platform and rent it out to another
                  player to generate passive income! Other players will be able to rent your room for a specified amount
                  of time and use it to their liking. Once the renting period ends you’ll be able to move back in just
                  as easily as you left it.
                </div>
                <div className='text-lg font-semibold'>Amplify all your earnings</div>
                <div>
                  Just like all our NFTs, rooms also have different rarities. These rarities offer both staking
                  multipliers, and earning multipliers. The bigger your room, the more furniture you can fit into your
                  room. If a room contains more furniture, the cumulative value of the room drastically increases. This
                  means that the maximum rent that you can ask, exponentially increases with the size of the room.
                </div>
                <Typography
                  className='self-center mt-6 font-bold mb-4'
                  style={{
                    fontSize: '32px',
                  }}
                >
                  Roadmap
                </Typography>
                <img className='self-center w-full' src={require('assets/projects/bithotel/info.png').default} />
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BitHotelLanding;
