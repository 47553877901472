import { AppBar, Button, Link as MuiLink, ListItemButton, Popover, Toolbar } from '@mui/material';
import { NetworkBar } from 'components';
import { usePopover } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connectWalletOnly } from 'reducers/profileAction';
import { signOut } from 'reducers/profileSlice';
import { privateRoute } from 'routes';
import { shorten } from 'utils/common';

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoggedIn, address } = useSelector(({ profile }) => profile);

  const [isOpen, anchor, onClick, onClose] = usePopover();
  const isNetwork = location.pathname.startsWith(privateRoute.home.path);

  return (
    <AppBar position='sticky' elevation={0} style={{ backgroundColor: '#232323' }}>
      <Toolbar>
        <Link to={privateRoute.home.path}>
          <img src={require('assets/icons/logo-spores-invert.svg').default} className='h-8 sm:h-10' />
        </Link>
        <div className='mx-6'>{isNetwork && <NetworkBar visible />}</div>

        <div className='flex-1 flex items-center justify-end'>
          <MuiLink href='https://marketplace.spores.app' className='text-gray-200 hover:text-white mr-6'>
            Marketplace
          </MuiLink>
          <MuiLink href='https://republique.spores.app/' className='text-gray-200 hover:text-white mr-6'>
            République
          </MuiLink>
          <MuiLink href='https://games.spores.app' className='text-gray-200 hover:text-white mr-6'>
            Games
          </MuiLink>

          {isNetwork && (
            <div className='sm:inline hidden'>
              {!isLoggedIn ? (
                <Button onClick={() => connectWalletOnly()}>Connect wallet</Button>
              ) : (
                <div className='flex'>
                  <div>
                    <Button
                      size='small'
                      variant='text'
                      className='text-gray-300 text-sm px-4'
                      style={{ backgroundColor: '#40394F', border: '1px solid #5E6484' }}
                      onClick={onClick}
                    >
                      {shorten(address)}
                    </Button>
                    <Popover open={isOpen} anchorEl={anchor} onClose={onClose}>
                      <ListItemButton
                        className='text-sm'
                        onClick={() => {
                          onClose();
                          navigator.clipboard.writeText(address);
                        }}
                      >
                        Copy Address
                      </ListItemButton>
                      <ListItemButton
                        className='text-sm'
                        onClick={() => {
                          onClose();
                          dispatch(signOut());
                        }}
                      >
                        Disconnect
                      </ListItemButton>
                    </Popover>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
