import { Grid, Typography, Link as MuiLink } from '@mui/material';
import React from 'react';
import { RitestreamLeftCard } from 'views/Ritestream/components';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const RitestreamLanding = () => {
  const [item, setItem] = React.useState(0);
  const handleClickCard = (name) => {
    setItem(name);
  };
  const BundleTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#48484A',
        width: '800px',
      },
    }),
  );
  return (
    <div className='text-white'>
      <div
        className='p-4 lg:px-20 lg:pt-8 flex flex-col items-center justify-center'
        style={{
          background: `url(${require('assets/projects/animalia/background-landing.png').default})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          paddingBottom: 180,
        }}
      >
        <Grid
          container
          spacing={4}
          className='flex justify-center items-start'
          style={{
            maxWidth: '1500px',
          }}
        >
          <Grid item xs={12} md={12} lg={4} xl={4} className='xl:sticky top-10'>
            <RitestreamLeftCard />
          </Grid>
          <Grid item md={12} lg={8} xl={8}>
            <div
              style={{
                backgroundColor: '#2C2C2E',
                borderRadius: '8px',
              }}
              className='p-4 md:p-8 justify-between max-w-screen-xl'
            >
              <img className='mb-8 w-full' src={require('assets/projects/ritestream/RitestreamBanner.png').default} />
              <Typography paragraph color='silver' align='justify' className='mb-4'>
                Ritestream - a world where creators can bring their film and television projects to life in a matter of
                days. Ritestream’s vision is to empower creators from ideation to global distribution. With a
                decentralized NFT market for film and Television, ritestream will drive efficiency and cost savings for
                broadcasters, and enable access to personalized content choice for viewers.
              </Typography>
              <div className='flex my-4'>
                <MuiLink
                  href='https://ritestream.io/'
                  className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
                  title='Official Website'
                >
                  <img src={require('assets/icons/media/website.svg').default} />
                </MuiLink>
                <MuiLink
                  href='https://discord.com/invite/RJPj522Qcc'
                  className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
                  title='Discord'
                >
                  <img src={require('assets/icons/media/discord.svg').default} />
                </MuiLink>
                <MuiLink
                  href='https://t.me/joinchat/0_LocCZYTZAwOTNl'
                  className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
                  title='Telegram'
                >
                  <img src={require('assets/icons/media/telegram.svg').default} />
                </MuiLink>
                <MuiLink
                  href='https://www.linkedin.com/showcase/ritestream/'
                  className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
                  title='Linkedin'
                >
                  <img src={require('assets/icons/media/linkedin.svg').default} />
                </MuiLink>
                <MuiLink
                  href='https://twitter.com/ritestream_nft'
                  className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
                  title='Twitter'
                >
                  <img src={require('assets/icons/media/twitter.svg').default} />
                </MuiLink>
              </div>
              <div className='flex flex-col 2xl:flex-row 2xl:justify-between justify-center items-start'>
                <div
                  className=' p-7 rounded-lg mb-8 2xl:w-full md:w-8/12 sm:10/12 w-full'
                  style={{ backgroundColor: '#3A3A3C' }}
                >
                  <div
                    className='mb-2 font-bold'
                    style={{ borderBottom: '1px #6E757E solid', lineHeight: '28px', fontSize: '18px' }}
                  >
                    Sale details
                  </div>
                  <div className='flex mt-2 justify-between items-end md:text-left'>
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      Access type
                    </div>
                    <div className='font-semibold' style={{ fontSize: '16px', color: '#ffffff' }}>
                      Whitelisted VIP only
                    </div>
                  </div>
                  <div className='flex mt-2 justify-between items-end md:text-left'>
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      KYC
                    </div>
                    <div className='font-semibold' style={{ fontSize: '16px', color: '#ffffff' }}>
                      Yes
                    </div>
                  </div>
                  <div className='flex mt-2 justify-between items-end md:text-left'>
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      VIP Allocation round
                    </div>
                    <div className='font-semibold' style={{ fontSize: '16px', color: '#ffffff' }}>
                      8 Mar 2022, 03:00 am UTC
                    </div>
                  </div>
                  <div className='flex mt-2 justify-between items-end md:text-left'>
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      Double limit round
                    </div>
                    <div className='font-semibold' style={{ fontSize: '16px', color: '#ffffff' }}>
                      9 Mar 2022, 03:00 am UTC
                    </div>
                  </div>
                  <div className='flex mt-2 justify-between items-end md:text-left'>
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      FCFS (VIP)
                    </div>
                    <div className='font-semibold' style={{ fontSize: '16px', color: '#ffffff' }}>
                      9 Mar 2022, 04:00 am UTC
                    </div>
                  </div>
                </div>
                <div className='w-1/12 hidden 2xl:flex'></div>
                <div
                  className='p-7 rounded-lg mb-8 2xl:w-full md:w-8/12 sm:10/12 w-full'
                  style={{ backgroundColor: '#3A3A3C' }}
                >
                  <div
                    className='mb-2 font-bold'
                    style={{ borderBottom: '1px #6E757E solid', lineHeight: '28px', fontSize: '18px' }}
                  >
                    Token Metrics
                  </div>
                  <div className='flex mt-2 justify-between items-end md:text-left'>
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      Token name
                    </div>
                    <div className='flex'>
                      <div
                        className='font-semibold'
                        style={{
                          fontSize: '16px',
                          color: '#ffffff',
                        }}
                      >
                        RITE
                        <MuiLink href='https://coinmarketcap.com/currencies/ritestream/ico/'>
                          <img className='inline ml-2' src={require('assets/projects/ritestream/rite.png').default} />
                        </MuiLink>
                      </div>
                    </div>
                  </div>
                  <div className='flex mt-2 justify-between items-end md:text-left'>
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      Price
                    </div>
                    <div className='font-semibold' style={{ fontSize: '16px', color: '#ffffff' }}>
                     $0.04
                    </div>
                  </div>
                  <div className='flex mt-2 justify-between items-end md:text-left'>
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      Blockchain Network
                    </div>
                    <div className='font-semibold' style={{ fontSize: '16px', color: '#ffffff' }}>
                      Binance Smart Chain (BNB)
                    </div>
                  </div>
                  <div className='flex mt-2 justify-between items-end md:text-left'>
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      Initial Supply
                    </div>
                    <div className='font-semibold' style={{ fontSize: '16px', color: '#ffffff' }}>
                      7,250,000 $RITE
                    </div>
                  </div>
                  <div className='flex mt-2 justify-between items-end md:text-left'>
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      Total Supply
                    </div>
                    <div className='font-semibold' style={{ fontSize: '16px', color: '#ffffff' }}>
                      1,000,000,000 $RITE
                    </div>
                  </div>
                  <div className='flex mt-2 justify-between items-end md:text-left'>
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      Initial Market Capitalization
                    </div>
                    <div className='font-semibold' style={{ fontSize: '16px', color: '#ffffff' }}>
                      $290,000
                    </div>
                  </div>
                  <div className='flex mt-2 justify-between items-end md:text-left'>
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      Total Raise (All Rounds)
                    </div>
                    <div className='font-semibold' style={{ fontSize: '16px', color: '#ffffff' }}>
                      $2,950,000
                    </div>
                  </div>
                  <div className='flex mt-2 justify-between items-end md:text-left'>
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      Platform Allocation
                    </div>
                    <div className='font-semibold' style={{ fontSize: '16px', color: '#ffffff' }}>
                      $50,000
                    </div>
                  </div>
                  <div className='flex mt-2 justify-between items-end md:text-left'>
                    <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                      Vesting Details
                    </div>
                    <div className='font-semibold' style={{ fontSize: '16px', color: '#ffffff' }}>
                      25% TGE then 3 months daily vesting
                    </div>
                  </div>
                </div>
              </div>
              <Grid className='flex flex-col'>
                <div className='font-bold text-center mb-8' style={{ fontSize: '32px', style: 'normal' }}>
                  Project information
                </div>
                <video
                  className='mb-6'
                  src={
                    'https://spores-marketplace-assets-dev.s3.ap-southeast-1.amazonaws.com/ef355cd4-71d6-4aa4-afb3-5eeca7671ccf'
                  }
                  muted
                  autoPlay
                  loop
                  controls
                />
                <img
                  className='self-center mb-6 w-full'
                  src={require('assets/projects/ritestream/info2.png').default}
                />
                <img
                  className='self-center mb-6 w-full'
                  src={require('assets/projects/ritestream/info3.png').default}
                />
                <img
                  className='self-center mb-6 w-full'
                  src={require('assets/projects/ritestream/info4.png').default}
                />
                <img
                  className='self-center mb-6 w-full'
                  src={require('assets/projects/ritestream/info5.png').default}
                />
                <img
                  className='self-center mb-6 w-full'
                  src={require('assets/projects/ritestream/info6.png').default}
                />
                <img className='self-center w-full' src={require('assets/projects/ritestream/info7.png').default} />
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div
          columns={12}
          className='flex justify-center items-start mt-10'
          style={{
            maxWidth: '1500px',
          }}
        ></div>
      </div>
    </div>
  );
};

export default RitestreamLanding;
