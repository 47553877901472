import { DesignButton, DesignContainer } from 'components';
import { Link, Paper, Typography, Link as MuiLink } from '@mui/material';
import React from 'react';
import { CampTimer } from 'utils/campaign';

const DinoXGuide = () => {
  return (
    <DesignContainer title='How to participate'>
      <div style={{ backgroundColor: 'transparent' }} className='text-white p-3 sm:p-6 mb-6'>
        <div className='font-black text-xl mb-2 mt-4'>1. CONNECT WALLET</div>
        <div>
          On visiting Spores Launchpad, you are required to connect your Metamask wallet on{' '}
          <span className='font-black'> Binance Smart Chain </span> network. Please ensure that you have sufficient
          balance in your wallet before taking part in the sales so you will not miss any desired item.
        </div>
      </div>

      <div style={{ backgroundColor: 'transparent' }} className='text-white p-3 sm:p-6 mb-6'>
        <div className='font-black text-xl mb-2'>2. STAKE & WHITELIST</div>
        <div className='mb-4'>
          You can participate in<span className='font-black'> DinoX Land Presale </span>as either a public customer or a
          VIP customer. Participants must fill out the form of interest in order to be whitelisted and qualified for VIP
          access. Spores will confirm your VIP level along with your entry time for the sale after we have reviewed your
          application. VIP customers can access the sales earlier and purchase NFTs with higher quota as well as get the
          better discount. After all VIP tiers have completed their purchase round, public customers can join and make
          purchases (subject to available inventory)
        </div>
        <div>
          Whitelist: <span className='font-bold text-yellow-300'>23 Feb 2022 to 02 Mar 2022, 1:00 PM UTC</span>
        </div>
        <table className='text-center mb-6 mt-2' style={{ backgroundColor: '#3A3A3C', border: '1px solid #624266' }}>
          <thead className='font-black'>
            <tr>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>VIP Level</td>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>Requirement</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>VIP 3</td>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>Top 25 SPO holders + top 5 LP staked</td>
            </tr>
            <tr>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>VIP 2</td>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>Min 100K SPO staked</td>
            </tr>
            <tr>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>VIP 1</td>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>Min 50K SPO staked</td>
            </tr>
            <tr>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>Public Round</td>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>FCFS</td>
            </tr>
          </tbody>
        </table>
        <Link href='https://docs.google.com/forms/d/e/1FAIpQLSdNUkv0dUbwXnz9PB4sV6qhFLK8nRruw7iz3IjfwLd8xhF-RQ/viewform'>
          <DesignButton disabled>GET VIP ACCESS</DesignButton>
        </Link>
      </div>

      <div style={{ backgroundColor: 'transparent' }} className='text-white p-3 sm:p-6'>
        <div className='font-black text-xl mb-2'>3. PARTICIPATE</div>
        <div className='mb-2 flex flex-wrap'>
          <img src={require('assets/icons/Bell.svg').default} className='h-6' />
          <span className='font-black'>&nbsp;DinoX Land Presale&nbsp;</span> will happen from&nbsp;
          <span className='font-black'>Thursday, 03 Mar 2022, 01:00 PM UTC&nbsp;</span> to&nbsp;
          <span className='font-black'>Sunday, 06 Mar 2022, 01:00 PM UTC&nbsp;</span>
          <img src={require('assets/icons/Bell.svg').default} className='h-6' />
        </div>
        <div className='text-justify mb-2'>
          You can participate in <span className='font-black'>DinoX Land Presale </span> as either a public customer or
          a VIP customer. VIP customers can access the sales earlier. After all VIP tiers have completed their purchase
          round, public customers can join and make purchases (subject to available inventory).
        </div>
        <table className='text-center mb-6 mt-2' style={{ backgroundColor: '#3A3A3C', border: '1px solid #624266' }}>
          <thead className='font-black'>
            <tr>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>VIP Level</td>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>Entry time (UTC)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>VIP 3</td>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>01:00 PM, 03 Mar 2022</td>
            </tr>
            <tr>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>VIP 2</td>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>01:30 PM, 03 Mar 2022</td>
            </tr>
            <tr>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>VIP 1</td>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>02:00 PM, 03 Mar 2022</td>
            </tr>
            <tr>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>Public Round</td>
              <td className='border border-gray-500 h-10 w-52 px-1 w-72'>02:30 PM, 03 Mar 2022</td>
            </tr>
          </tbody>
        </table>
        <div className='text-justify mb-2'>
          You can find the most up-to-date information about the <span className='font-black'>DinoX Land Presale </span>{' '}
          <MuiLink
            className='hover:underline text-yellow-300 font-medium'
            href='https://spores.medium.com/spores-dinox-land-nft-collection-drop-details-57c7f09c5936'
          >
            here
          </MuiLink>
        </div>
      </div>
    </DesignContainer>
  );
};

export default DinoXGuide;
