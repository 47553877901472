import { Add, Remove } from '@mui/icons-material';
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  LinearProgress,
  linearProgressClasses,
  Typography,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as Star } from 'assets/projects/animalia/star.svg';
import { CountdownFlipAnimalia, PopoverInfo } from 'components';
import { erc20Contract, web3 } from 'contracts/contract';
import { usePopup } from 'hooks';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { connectWallet } from 'reducers/profileAction';
import { marketService } from 'services/market';
import { storageService } from 'services/storage';
import { CampTimer } from 'utils/campaign';
import { formatNumber } from 'utils/common';
import { PopupCart, PopupDone } from 'views/Checkout';
import { ReactComponent as KeyboardArrowDownIcon } from '../../../assets/projects/dinoX/KeyboardArrowDownIcon.svg';
import { ReactComponent as WarningImg } from 'assets/icons/warning.svg';

export const TotalRaiseProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 18,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background: 'linear-gradient(90deg, #F1F1F5 0%, #E4ECF7 100%)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 18,
    background: 'linear-gradient(90deg, rgba(73,108,251,1) 0%, rgba(214,101,255,1) 100%)',
  },
}));

export const ButtonCurrency = styled(Button)(({ theme }) => ({
  background: '#3A3A3C',
  '&:hover': {
    background: '#3A3A3C',
  },
  height: '36px',
  width: '75px',
  boxSizing: 'border-box',
  borderRadius: '8px',
  color: '#9994A3',
  fontSize: '14px',
  '&.active': {
    color: '#ffffff',
    boxShadow: '0px 3px 20px #7000FF',
    background: '#7659AE',
    '&:hover': {
      background: '#7659AE',
    },
  },
}));

function WizardiaRightCard() {
  const { isLoggedIn, address } = useSelector(({ profile }) => profile);
  const [currency, setCurrency] = React.useState({
    name: 'USDT',
    index: 0,
  });
  const handClickCurrency = (event, index) => {
    setCurrency({ name: event.target.value, index: index });
  };

  //get TIme campaign
  const { data: time = {} } = useQuery(['marketService.fetchCampaignsTime'], () => marketService.fetchCampaignsTime(), {
    refetchOnMount: 'always',
    refetchInterval: 60 * 1000,
    staleTime: 0,
  });

  //get Quota of user level
  const { data: quota = {}, refetch: refetchQuota } = useQuery(
    ['marketService.getQuota'],
    () => marketService.getQuota(),
    {
      refetchOnMount: 'always',
      enabled: isLoggedIn,
      refetchInterval: 60 * 1000,
      staleTime: 0,
    },
  );

  const allocation = quota.allocated_amount;

  const vipLevel = quota.name;
  const startTime = quota.can_buy_time;

  //get user allocation can buy
  const { data: amount = {}, refetch: refetchAmount } = useQuery(
    ['marketService.getMaxAmount'],
    () => marketService.getMaxAmount(),
    {
      refetchOnMount: 'always',
      enabled: isLoggedIn,
      refetchInterval: 60 * 1000,
      staleTime: 0,
    },
  );
  const userAllocation = amount;

  // start: time countdown
  const isStarted = useMemo(() => new Date(time.open_time).getTime() <= Date.now(), [time.open_time]);
  const isFinished = useMemo(() => new Date(time.close_time).getTime() <= Date.now(), [time.close_time]);
  const currentRound = useMemo(() => {
    if (!isStarted) return -1;
    else if (!isFinished) {
      if (new Date(time.fcfs_round_time).getTime() <= Date.now()) {
        return 3;
      } else if (new Date(time.double_limit_round_time).getTime() <= Date.now()) {
        return 2;
      } else {
        return 1;
      }
    }
    return 0;
  }, [time, isStarted, isFinished]);
  const times = useMemo(() => {
    switch (currentRound) {
      case -1: // not started
        return [new Date().toISOString(), time.open_time];
        break;
      case 0: // finished
        return [];
        break;
      case 1: // step 1
        return [time.open_time, time.double_limit_round_time];
        break;
      case 2: // step 2
        return [time.double_limit_round_time, time.fcfs_round_time];
        break;
      case 3: // step 3
        return [time.fcfs_round_time, time.close_time];
        break;
    }
  }, [time, currentRound]);
  const countdownLable = useMemo(() => {
    switch (currentRound) {
      case -1: // not started
        return 'Sale starts in';
      case 0: // finished
        return 'SALE HAS ENDED';
      case 1: // step 1
        return 'VIP round ends in';
      case 2: // step 2
        return 'VIP Double Quota ends in';
      case 3: // step 3
        return 'FCFS (VIP access only) ends in';
    }
  }, [time, currentRound]);
  // end: time countdown

  //get list Product
  const { data = [], refetch: refetchProduct } = useQuery(
    ['marketService.fetchProducts'],
    () => marketService.fetchProducts(),
    {
      refetchOnMount: 'always',
    },
  );

  const products = React.useMemo(() => {
    return data
      .sort((a, b) => a.product_id - b.product_id)
      .map((item) => {
        return {
          product_id: item?.product_id,
          prices: item?.product?.price,
          name: item?.product.name,
          remain_amount: item?.remain_quantity,
          amount: item?.product.amount,
        };
      });
  }, [data]);

  const amountLeft = products[0]?.remain_amount;

  //get product selected
  const product = React.useMemo(() => {
    let arr = products;
    return { ...arr[0] };
  }, [products]);
  //fetch new user level and product
  const onRefresh = () => {
    refetchQuota();
    refetchProduct();
    refetchAmount();
  };
  //amount quantity of item
  const [amounts, setAmounts] = React.useState();

  const totalPrice = amounts;

  //dialog
  const [isOpenCart, onOpenCart, onCloseCart] = usePopup(() => {
    let { createdAt, isContinue = false } = storageService.getOrder();
    if (createdAt) {
      const endTime = DateTime.fromISO(createdAt).plus({ minutes: 15 });
      isContinue = endTime > DateTime.now();
    }
    if (!isContinue) storageService.clearOrder();
    return isContinue;
  });
  const [isOpenDone, onOpenDone, onCloseDone] = usePopup();

  //checkout
  const currentPrice = product?.prices?.[currency.index];
  const { mutate: checkout } = useMutation(marketService.checkout, {
    onSuccess: (data, body) => {
      const { order } = data;
      storageService.saveOrder({
        orderId: order.id,
        createdAt: order.CreatedAt,
        ...body,
        discount: 0,
        totalDiscount: 0,
        currentPrice,
        totalPrice: totalPrice,
      });

      setTimeout(() => {
        onOpenCart();
        onRefresh();
      }, 0);
    },
  });
  const [isEnoughBalance, setIsEnoughBalance] = React.useState(true);

  const checkBalance = async () => {
    const { payment_token } = currentPrice;
    const balance = payment_token?.address
      ? await erc20Contract(payment_token?.address).methods.balanceOf(address).call()
      : await web3.eth.getBalance(address);
    const isEnough = Number(balance) >= totalPrice * 10 ** (payment_token.decimal || 18);
    return isEnough;
  };

  const updateUiStateAfterCheckBalance = () => {
    setIsEnoughBalance(false);
    setTimeout(() => {
      setIsEnoughBalance(true);
    }, 5000);
  };

  const handleClickCheckout = async () => {
    const isEnough = await checkBalance();
    if (!isEnough && vipLevel !== 'VIP 0') {
      updateUiStateAfterCheckBalance();
      return;
    }

    checkout({
      products: [
        {
          product_id: 1,
          amount: amounts,
          name: 'WZRD',
        },
      ],
      currency: currency.name,
    });
  };

  //Tooltip
  const BundleTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(55, 46, 73, 0.8)',
        padding: '5px',
        border: '1px #624266 solid',
      },
    }),
  );

  return (
    <div>
      <div className='p-7 rounded-lg' style={{ backgroundColor: '#2C2C2E' }}>
        <div className='flex space-x-4 mb-4 justify-center xl:justify-start'>
          <img
            src={require('assets/projects/wizardia/ava.png').default}
            className='rounded-lg h-12 w-12 border-white border-4'
          />
          <div className=''>
            <div style={{ fontSize: '32px' }} className='text-white font-bold'>
              Wizardia IDO{' '}
            </div>
          </div>
        </div>

        {products.map((item, index) => {
          return (
            <div key={index} className='mt-6'>
              <div className='flex my-2 justify-between items-end md:text-left'>
                <div
                  className='font-normal text-white'
                  style={{
                    fontSize: '16px',
                  }}
                >
                  ${formatNumber(item.amount - item.remain_amount)} raised
                </div>
                <div
                  className='font-normal text-white'
                  style={{
                    fontSize: '16px',
                  }}
                >
                  {Math.round(((item.amount - item.remain_amount) / item.amount) * 100)}% sold
                </div>
              </div>
              <TotalRaiseProgress
                className='my-2'
                variant='determinate'
                value={((item.amount - item.remain_amount) / item.amount) * 100}
              ></TotalRaiseProgress>
              <div className='flex mt-2 justify-between items-end md:text-left'>
                <div
                  className='font-normal'
                  style={{
                    fontSize: '16px',
                    color: '#A2A5A9',
                  }}
                >
                  Targeted Raise
                </div>
                <div
                  className='font-bold text-white'
                  style={{
                    fontSize: '16px',
                  }}
                >
                  ${formatNumber(item.amount)}
                </div>
              </div>
              {item.remain_amount ? (
                <div>
                  {!isFinished ? (
                    <div className='flex text-base justify-between mt-8'>
                      <div
                        className='font-bold bg-clip-text text-transparent'
                        style={{
                          background:
                            '-webkit-linear-gradient(90deg, rgba(255,130,130,1) 0%, rgba(255,196,79,1) 70%, rgba(255,81,0,1) 100%)',
                        }}
                      >
                        {countdownLable}
                      </div>
                    </div>
                  ) : (
                    <div className='flex text-base justify-center'>
                      {amountLeft !== 0 ? (
                        <div
                          className='font-bold bg-clip-text text-transparent mt-8'
                          style={{
                            background:
                              '-webkit-linear-gradient(90deg, rgba(255,130,130,1) 0%, rgba(255,196,79,1) 70%, rgba(255,81,0,1) 100%)',
                          }}
                        >
                          {countdownLable}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <p
                  className='text-xl mt-6 text-center font-bold bg-clip-text text-transparent'
                  style={{
                    background:
                      '-webkit-linear-gradient(90deg, rgba(255,130,130,1) 0%, rgba(255,196,79,1) 70%, rgba(255,81,0,1) 100%)',
                    // '-webkit-background-clip': 'text',
                    // '-webkit-text-fill-color': 'transparent',
                  }}
                >
                  SOLD OUT
                </p>
              )}

              {!vipLevel && (
                <div
                  className='flex w-full h-14 rounded justify-center items-center mt-4'
                  style={{ backgroundColor: '#EBECF0' }}
                >
                  <span style={{ color: '#C1C4D0' }}>VIP ACCESS ONLY</span>
                </div>
              )}
            </div>
          );
        })}

        <Dialog open={isOpenCart} maxWidth='md'>
          <PopupCart onClose={onCloseCart} onSuccess={onOpenDone} onRefresh={onRefresh} />
        </Dialog>
        <Dialog open={isOpenDone} onClose={onCloseDone} maxWidth='md'>
          <PopupDone />
        </Dialog>
      </div>
      {isStarted && isLoggedIn && vipLevel && !isFinished && amountLeft !== 0 ? (
        <div className='p-7 rounded-lg mt-2' style={{ backgroundColor: '#2C2C2E', color: '#80858C' }}>
          <div className='flex flex-col w-full'>
            <div className='mb-4 flex justify-between items-center'>
              <span className=''>VIP Level</span>
              <span className='font-bold text-white'>{vipLevel}</span>
            </div>

            <div className='mb-4 flex justify-between items-center'>
              <span className=''>Your allocation</span>
              <span className='font-bold text-white'>
                {vipLevel === 'VIP 0' || userAllocation === 100000 ? 'Unlimited' : `$${formatNumber(userAllocation)}`}
              </span>
            </div>

            <div className='mb-3 flex justify-between items-center'>
              <span className=''>Select currency</span>
              <div className='flex flex-row mt-1'>
                {product?.prices?.map((item, index) => (
                  <ButtonCurrency
                    className={item.payment_token.name === currency.name ? 'active ml-2' : 'ml-2'}
                    value={item.payment_token.name}
                    onClick={(e) => handClickCurrency(e, index)}
                  >
                    {item.payment_token.name}
                  </ButtonCurrency>
                ))}
              </div>
            </div>
            <span className='mb-1'>Input amount</span>
            <div className='mb-4'>
              <div
                style={{ height: '62px', background: '#3A3A3C', borderRadius: '8px' }}
                className='flex justify-between p-4 text-white'
              >
                <NumberFormat
                  defaultValue={0}
                  value={amounts}
                  onValueChange={({ floatValue: value }) => setAmounts(value)}
                  onBlur={() => {
                    if (!amounts) setAmounts(0);
                    if (amounts > userAllocation) setAmounts(amountLeft);
                    // if (userAllocation === 100000 && amounts > amountLeft) setAmounts(amountLeft);
                  }}
                  maxLength={9}
                  allowNegative={false}
                  decimalScale={0}
                  className='text-start bg-transparent text-2xl font-bold numberinput--placeholder'
                  style={{ maxWidth: '80%' }}
                />
                <div className='flex-none'>
                  {vipLevel !== 'VIP 0' && userAllocation !== 100000 && (
                    <button
                      onClick={() => setAmounts(userAllocation)}
                      style={{
                        width: '44px',
                        height: '30px',
                        background: '#2C2C2E',
                        borderRadius: '4px',
                      }}
                    >
                      Max
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className='mb-4 flex'>
              <span className='font-medium text-xs'>You will pay </span>
              <span className='font-medium text-xs text-white ml-2'>
                {`${amounts ? formatNumber(totalPrice) : 0} ${currency.name}`}
              </span>
            </div>
            {userAllocation === 0 && (
              <div className='flex justify-center mb-4'>
                <WarningImg className='mr-1' />
                <span className='text-error'>You’ve reached your maximum quota</span>
              </div>
            )}
            {!isEnoughBalance && (
              <div className='flex justify-center mb-4'>
                <WarningImg className='mr-1' />
                <span className='text-error'>Insufficient balance</span>
              </div>
            )}
            {amounts > amountLeft && (
              <div className='flex justify-center mb-4'>
                <WarningImg className='mr-1' />
                <span className='text-error'>Incorrect amount</span>
              </div>
            )}
            <Button
              disabled={!isEnoughBalance || userAllocation === 0 || !amounts || amounts > amountLeft}
              className='h-14 text-base font-semibold'
              onClick={handleClickCheckout}
            >
              CHECK OUT
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default WizardiaRightCard;
