import { Grid, Typography, Link as MuiLink } from '@mui/material';
import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const WizardiaInventory = () => {
  return (
    <div style={{ color: '#A2A5A9', fontSize: '18px' }}>
      <div>
        Wizardia is a Play-to-Earn online role-playing strategy game with unique NFTs at its core. The game is a
        turn-based battle with RPG elements (character progression, artifact upgrades, and crafting). Players obtain and
        upgrade wizards and use them to do battle - PVE and PVP - in the Arena.
      </div>
      <div className='flex my-6'>
        <MuiLink
          href='https://wizardia.io/'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Official Website'
        >
          <img src={require('assets/icons/media/website.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://discord.com/invite/wizardia'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Discord'
        >
          <img src={require('assets/icons/media/discord.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://t.me/Wizardia'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Telegram'
        >
          <img src={require('assets/icons/media/telegram.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://twitter.com/PlayWizardia'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Twitter'
        >
          <img src={require('assets/icons/media/twitter.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://medium.com/@wizardia'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100'
          title='Medium'
        >
          <img src={require('assets/icons/media/medium.svg').default} />
        </MuiLink>
        <MuiLink
          href='https://www.tiktok.com/@wizardia'
          className='text-gray-200 hover:text-white mr-3 opacity-50 hover:opacity-100 flex'
          title='Medium'
        >
          <img src={require('assets/icons/media/tiktok.svg').default} />
        </MuiLink>
      </div>
      <div className='font-bold text-white py-4' style={{ fontSize: '24px' }}>
        The Wizardia Token
      </div>
      <div className='mb-4'>
        $WZRD is a utility token that also acts as an in-game currency powered by the high-performance Solana
        blockchain. $WZRD tokens can be spent as well as earned or exchanged by every player inside and outside of the
        game.
      </div>
      <div className='mb-4'>
        The game is built on unique Non-Fungible Tokens (NFTs) that have in-game purposes. Players can take advantage of
        NFT perks or sell them to other players or investors.
      </div>
      <div className='mb-4'>
        The $WZRD token will be at the center of Wizardia’s gameworld. Fees will be collected through game mechanics
        including transactions, tournament participation, magic discovery, crafting, and construction. Tokens will be
        distributed back into the game economy through staking, quest completion, PvP / PvE rewards and event
        participation.
      </div>
      <img className='mb-8 w-full' src={require('assets/projects/wizardia/info1.png').default} />
      <div className='font-bold text-white py-4' style={{ fontSize: '24px' }}>
        NFTs in the Wizardia World
      </div>
      <div className='mb-8'>Two varieties of Wizardia NFTs have been announced so far:</div>
      <ul className='list-disc m-2 ml-6 mb-8'>
        <li>
          <span className='font-bold text-white'>Arena Genesis NFTs —</span> representing a slice of the gameworld’s
          battle Arena infrastructure, which reward holders with passive income in the form of pre-release staking
          rewards and a proportional cut of post-launch Arena transaction revenues. The Arena Genesis NFT sale is live
          on wizardia.io. Sales rounds continue after selling out the first batch in 24 hours.
        </li>
        <li>
          <span className='font-bold text-white'> Wizard NFTs —</span> upgradable, tradable and rentable
          player-controlled characters with unique art and in-game skills. These legendary wizards and their stories
          will be introduced to the Wizardia community during Q1 and Q2 2022, leading up to a Wizard NFT sale in Q2.
        </li>
      </ul>
      <img className='mb-8 w-full' src={require('assets/projects/wizardia/info2.png').default} />
      <div className='font-bold text-white py-4' style={{ fontSize: '24px' }}>
        The Grand Plan for Wizardia
      </div>
      <div className='mb-8'>
        Gameplay modes will be released in a staggered fashion during 2022 with battle commencing in the first alpha
        version of the Arena in Q2/Q3. Players will lead teams of 3 wizards in PvP and PvE combat. Subsequent releases
        will introduce the full game functionality including global tournament play, base building, exploration and a
        VR/AR integration by the end of Q4.
      </div>
      <img className='mb-8 w-full' src={require('assets/projects/wizardia/info3.png').default} />
      <div className='font-bold text-white py-4' style={{ fontSize: '24px' }}>
        Meet The Team
      </div>
      <div className='mb-8'>
        The Wizardia team has core contributors with strong blockchain experience and a lineup of crypto-gaming experts
        as advisors. What sets the project apart is its core knowledge of game development. Team members have been
        involved on a long list of triple A games such as Call of Duty Black Ops series, League of Legends, and
        Titanfall.
      </div>
      <img className='mb-8 w-full' src={require('assets/projects/wizardia/info4.png').default} />
      <img className='mb-8 w-full' src={require('assets/projects/wizardia/info5.png').default} />
      <div className='mb-4'>
        Wizardia’s founders have been dreaming up the world of Wizardia, its setting and lore, in one way or another,
        for more than a decade.
      </div>
      <div className='mb-8'>
        The team strongly believes that to realize the full potential of the crypto economy of the project, building a
        captivating game must be their top priority. They’re excited to welcome crypto-enthusiasts and gamers alike into
        the rich and rewarding realm of Wizardia.
      </div>
      <img className='mb-8 w-full' src={require('assets/projects/wizardia/info6.png').default} />
    </div>
  );
};

export default WizardiaInventory;
