import React from 'react';
import { useQuery } from 'react-query';
import { Container, Table, TableHead, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { marketService } from 'services/market';
import { DateTime } from 'luxon';
import { shorten } from 'utils/common';
import { formatNumber } from 'utils/common';

const STATUS_MAP = {
  1: { name: 'PENDING', color: '#FF991F' },
  2: { name: 'PENDING', color: '#FF991F' },
  3: { name: 'SUCCESS', color: '#00875A' },
  4: { name: 'FAILED', color: '#DE350B' },
};

const RitestreamOrder = () => {
  const { data: dataList = [] } = useQuery(['marketService.searchSales'], () => marketService.fetchOrders());
  const Perx = 50;
  return (
    <Container className='py-10'>
      <div className='box-border p-8'>
        <Typography variant='h3' color='#E0C685' className='mb-6'>
          ORDER HISTORY
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>TRANSACTION ID</TableCell>
              <TableCell>DATE</TableCell>
              <TableCell>PRICE</TableCell>
              <TableCell>QUANTITY</TableCell>
              <TableCell>TOTAL PRICE</TableCell>
              <TableCell>STATUS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((item, index) => {
              if (!item.product_name[1]) {
                item.product_name[1] = '-';
                if (item.product_name[0] === 'Anxiety Rabbit') item.product_name[1] = 'Goth Rabbit';
                if (item.product_name[0] === 'Goth Rabbit') item.product_name[1] = 'Anxiety Rabbit';
                item.product_price[1] = item.product_price[0];
                item.product_quantity[1] = 0;
              }
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell rowSpan={2}>
                      <span title={item.transaction_hash}>{shorten(item.transaction_hash, 12, 10)}</span>
                    </TableCell>
                    <TableCell rowSpan={2}>{DateTime.fromISO(item.order_time).toFormat('HH:mm dd/MM/yyyy')}</TableCell>
                    <TableCell>0.04 {item.currency}</TableCell>
                    <TableCell>{formatNumber(item.total_amount / 0.04)} RITE</TableCell>
                    
                    <TableCell rowSpan={2}>
                      {formatNumber(item.total_amount)} {item.currency}
                    </TableCell>
                    <TableCell rowSpan={2}>
                      <span
                        className='text-white text-sm rounded px-4 py-1.5'
                        style={{ backgroundColor: STATUS_MAP[item.status].color }}
                      >
                        {STATUS_MAP[item.status].name}
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {/* <TableCell>{item.product_name[1]}</TableCell>
                    <TableCell>
                      {formatNumber(item.product_price[1])} {item.currency}
                    </TableCell>
                    <TableCell>{item.product_quantity[1]}</TableCell> */}
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Container>
  );
};

export default RitestreamOrder;
