import { Add, Remove } from '@mui/icons-material';
import { Button, Dialog, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { ReactComponent as Danger } from 'assets/projects/animalia/dangerIcon.svg';
import { ReactComponent as Star } from 'assets/projects/animalia/star.svg';
import { CountdownFlipAnimalia } from 'components';
import { erc20Contract, web3 } from 'contracts/contract';
import { usePopup } from 'hooks';
import { DateTime } from 'luxon';
import React from 'react';
import NumberFormat from 'react-number-format';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { connectWallet } from 'reducers/profileAction';
import { marketService } from 'services/market';
import { storageService } from 'services/storage';
import { CampTimer } from 'utils/campaign';
import { formatNumber } from 'utils/common';
import { PopupCart, PopupDone } from 'views/Checkout';
import { ReactComponent as KeyboardArrowDownIcon } from '../../../assets/projects/dinoX/KeyboardArrowDownIcon.svg';

function BitHotelLeftCard({ nameItem }) {
  const { isLoggedIn, address } = useSelector(({ profile }) => profile);
  const [currency, setCurrency] = React.useState({
    name: 'BUSD',
    index: 0,
  });

  //get TIme campaign
  const { data: time = {} } = useQuery(['marketService.fetchCampaignsTime'], () => marketService.fetchCampaignsTime(), {
    refetchOnMount: 'always',
    refetchInterval: 60 * 1000,
    staleTime: 0,
  });
  const { CloseTime, OpenTime } = time;

  //get Quota of user level
  const { data: quota = {}, refetch: refetchQuota } = useQuery(
    ['marketService.getQuota'],
    () => marketService.getQuota(),
    {
      refetchOnMount: 'always',
      enabled: isLoggedIn,
      refetchInterval: 60 * 1000,
      staleTime: 0,
    },
  );
  const startTime = quota.can_buy_time;
  //time countdown
  const [{ isStarted, isFinished, countdown }, setCounDown] = React.useState(CampTimer(OpenTime, CloseTime));
  React.useEffect(() => {
    if (isLoggedIn) {
      setCounDown(CampTimer(startTime, CloseTime));
    } else {
      setCounDown(CampTimer(OpenTime, CloseTime));
    }
  }, [startTime, OpenTime, isLoggedIn, CloseTime]);

  //select Item
  const [item, setItem] = React.useState(0);
  React.useEffect(() => {
    setItem(nameItem);
  }, [nameItem]);
  const handleChange = (event) => {
    setItem(event.target.value);
    setAmounts(1);
  };
  //get list Product
  const { data = [], refetch: refetchProduct } = useQuery(
    ['marketService.fetchProducts'],
    () => marketService.fetchProducts(),
    {
      refetchOnMount: 'always',
    },
  );

  const products = React.useMemo(() => {
    return data
      .sort((a, b) => a.product_id - b.product_id)
      .map((item) => {
        return {
          product_id: item?.product_id,
          prices: item?.product?.price,
          name: item?.product.name,
          remain_amount: item?.remain_quantity,
          image: item?.product.image,
        };
      });
  }, [data]);

  //get product selected
  const product = React.useMemo(() => {
    let arr = products.filter((pd) => pd.name === item);
    return { ...arr[0] };
  }, [item, products]);

  //fetch new user level and product
  const onRefresh = () => {
    refetchQuota();
    refetchProduct();
  };

  //amount quantity of item
  const [amounts, setAmounts] = React.useState(1);
  const setAmount = (value) => {
    setAmounts(value);
  };
  const totalPrice = amounts * product?.prices?.[currency.index]?.price;

  //dialog
  const [isOpenCart, onOpenCart, onCloseCart] = usePopup(() => {
    let { createdAt, isContinue = false } = storageService.getOrder();
    if (createdAt) {
      const endTime = DateTime.fromISO(createdAt).plus({ minutes: 15 });
      isContinue = endTime > DateTime.now();
    }
    if (!isContinue) storageService.clearOrder();
    return isContinue;
  });
  const [isOpenDone, onOpenDone, onCloseDone] = usePopup();

  //checkout
  const currentPrice = product?.prices?.[currency.index];
  const { mutate: checkout } = useMutation(marketService.checkout, {
    onSuccess: (data, body) => {
      const { order } = data;
      storageService.saveOrder({
        orderId: order.id,
        createdAt: order.CreatedAt,
        ...body,
        discount: 0,
        totalDiscount: 0,
        currentPrice,
        totalPrice: Number(order.total_amount),
      });

      setTimeout(() => {
        onOpenCart();
        onRefresh();
      }, 0);
    },
  });

  const [isEnoughBalance, setIsEnoughBalance] = React.useState(true);
  const [isSelectItem, setIsSelectItem] = React.useState(true);

  const handleClickCheckout = async () => {
    const isSelect = item !== 0;
    if (!isSelect || !Boolean(product.remain_amount)) {
      setIsSelectItem(false);
      setTimeout(() => {
        setIsSelectItem(true);
      }, 5000);
      return;
    }
    const { payment_token } = currentPrice;
    const balance = payment_token?.address
      ? await erc20Contract(payment_token.address).methods.balanceOf(address).call()
      : await web3.eth.getBalance(address);
    const isEnough = Number(balance) >= totalPrice * 10 ** (payment_token.decimal || 18);
    if (!isEnough) {
      setIsEnoughBalance(false);
      setTimeout(() => {
        setIsEnoughBalance(true);
      }, 5000);
      return;
    }

    checkout({
      products: [
        {
          product_id: product.product_id,
          amount: amounts,
          name: product.name,
        },
      ],
      currency: currency.name,
    });
  };

  return (
    <div>
      <div className='flex space-x-4 mb-4 justify-center xl:justify-start'>
        <img src={require('assets/projects/bithotel/ava.png').default} className='rounded-lg w-24 h-24' />
        <div className='flex flex-col items-start'>
          <Typography variant='h3' className='text-white'>
            Bit Hotel IRO
          </Typography>
          <span
            className='text-white h-8 flex items-center rounded-2xl px-3 text-sm mt-2'
            style={{ background: 'linear-gradient(225deg, #68DBF2 0.01%, #509CF5 100%)', width: 'auto' }}
          >
            <Star className='mr-2' /> FCFS
          </span>
          <Typography component='p' className=' mt-2' style={{ color: 'rgba(160, 174, 192, 1)' }}>
            BUSD
          </Typography>
        </div>
      </div>
      {!isFinished && (
        <div
          className='xl:max-w-xl flex flex-col items-center justify-center mb-6 p-3'
          style={{ background: '#3A3A3C', borderRadius: '8px' }}
        >
          <Typography variant='subtitle1' align='center' className='mb-4 text-sm text-white' letterSpacing={2}>
            {isStarted ? 'Sale ends in' : 'Sale starts in'}
          </Typography>
          <CountdownFlipAnimalia {...countdown} />
        </div>
      )}

      {isStarted && isLoggedIn && !isFinished ? (
        <div className='flex flex-col'>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            className='flex px-0 py-2 h-13 rounded-lg mb-6 items-center'
            style={{ background: '#3A3A3C', borderRadius: '8px' }}
            value={item}
            IconComponent={KeyboardArrowDownIcon}
            onChange={handleChange}
          >
            <MenuItem value={0} className='data-none'>
              <div className='flex w-11/12 items-center'>
                <img src={require('assets/projects/dinoX/selecIcon.svg').default} className=' w-9 h-9 mx-2.5' />
                <Typography variant='p' className='flex-grow text-white text-sm uppercase'>
                  Select item
                </Typography>
              </div>
            </MenuItem>
            {products.map((item, index) => {
              return (
                <MenuItem
                  key={item.name}
                  value={item.name}
                  style={{
                    borderBottom: ' 1px solid #3a3a3c',
                    backgroundColor: '#1c1c1e',
                  }}
                >
                  <div className='flex w-11/12 items-center'>
                    <img
                      className=' w-9 h-9 mx-2.5'
                      style={{ filter: `${item.remain_amount ? '' : 'grayscale(1)'} ` }}
                      src={item.image}
                    />
                    <Typography
                      variant='h6'
                      className='flex-grow text-transparent bg-clip-text'
                      style={{
                        background: '-webkit-linear-gradient(270deg, #FFEF5E 30%,  #F7936F 70%)',
                        filter: `${item.remain_amount ? '' : 'grayscale(1)'} `,
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Typography component='p' className='text-sm leading-7 mr-3' style={{ color: '#C0B9CE' }}>
                      {item.remain_amount ? (
                        `${formatNumber(item.remain_amount)} left`
                      ) : (
                        <span className='text-red-600 text-sm'>SOLD OUT</span>
                      )}
                    </Typography>
                  </div>
                </MenuItem>
              );
            })}
          </Select>
          {item !== 0 && Boolean(product.remain_amount) && (
            <>
              <div
                style={{ minHeight: '116px', background: '#3A3A3C', borderRadius: '8px' }}
                className='flex flex-col p-4 justify-center mb-3 text-white'
              >
                <div className='flex justify-between mb-4.5 mb-2'>
                  <span style={{ color: '#C0B9CE' }}>Price</span>
                  <span>
                    {parseInt(product?.prices?.[currency.index]?.price).toFixed(0)} {currency.name}
                  </span>
                </div>
                <div className='flex justify-between  items-center text-white'>
                  <span style={{ color: '#C0B9CE' }}>Quantity</span>
                  <div className='flex items-center rounded p-1' style={{ backgroundColor: '#1C1C1E' }}>
                    <IconButton size='small' className='mx-1' onClick={() => setAmount(Math.max(0, amounts - 1))}>
                      <Remove className='text-white' />
                    </IconButton>
                    <div
                      className='rounded font-medium p-1 border'
                      style={{ borderColor: '#636366', background: '#2C2C2E' }}
                    >
                      <NumberFormat
                        value={amounts}
                        onValueChange={({ floatValue: value }) => {
                          if (value > product.remain_amount) {
                            setAmount(product.remain_amount);
                          } else {
                            setAmount(value);
                          }
                        }}
                        onBlur={() => {
                          if (!amounts) setAmount(0);
                          if (amounts > product.remain_amount) setAmount(product.remain_amount);
                        }}
                        maxLength={3}
                        allowNegative={false}
                        className='w-11 text-center bg-transparent'
                      />
                    </div>
                    <IconButton
                      size='small'
                      className='mx-1'
                      onClick={() => setAmount(Math.min(product.remain_amount, amounts + 1))}
                    >
                      <Add className='text-white' />
                    </IconButton>
                  </div>
                </div>
              </div>
              <div
                className='flex flex-col p-4 mb-3 justify-center text-white'
                style={{ minHeight: '60px', background: '#3A3A3C', borderRadius: '8px' }}
              >
                <div className='flex justify-between mb-4.5 '>
                  <span
                    style={{
                      color: '#C0B9CE',
                    }}
                  >
                    Total
                  </span>
                  <span
                    className='bg-clip-text text-transparent font-semibold text-lg'
                    style={{
                      background: '-webkit-linear-gradient(90deg, #F7936F 10%, #FFEF5E 70%, #FFEF5E 70%)',
                    }}
                  >
                    {`${amounts ? formatNumber(totalPrice) : 0} ${currency.name}`}
                  </span>
                </div>
              </div>
            </>
          )}
          {(!isEnoughBalance || !isSelectItem) && (
            <div className='flex justify-center mb-4 mt-1'>
              <Typography className='flex' style={{ color: '#FF4841' }} component='p'>
                <Danger className='pr-1' /> {isSelectItem ? 'Insufficient balance' : 'Please select item'}
              </Typography>
            </div>
          )}
          <Button disabled={!isEnoughBalance} className='h-14 text-base font-semibold' onClick={handleClickCheckout}>
            CHECK OUT
          </Button>
        </div>
      ) : (
        <div className='flex flex-col items-center mt-4'>
          {!isLoggedIn ? (
            <Button className='w-18' onClick={() => connectWallet()}>
              Connect wallet
            </Button>
          ) : isFinished ? (
            <p className='text-lg font-bold' style={{ color: '#E4ECF7' }}>
              SALE HAS ENDED
            </p>
          ) : (
            <p
              className='text-lg font-bold bg-clip-text text-transparent'
              style={{
                background:
                  '-webkit-linear-gradient(90deg, rgba(255,130,130,1) 0%, rgba(255,196,79,1) 70%, rgba(255,81,0,1) 100%)',
                // '-webkit-background-clip': 'text',
                // '-webkit-text-fill-color': 'transparent',
              }}
            >
              COMING SOON
            </p>
          )}
        </div>
      )}
      <Dialog open={isOpenCart} maxWidth='md'>
        <PopupCart onClose={onCloseCart} onSuccess={onOpenDone} onRefresh={onRefresh} />
      </Dialog>
      <Dialog open={isOpenDone} onClose={onCloseDone} maxWidth='md'>
        <PopupDone />
      </Dialog>
    </div>
  );
}

export default BitHotelLeftCard;
