import { Button, LinearProgress, linearProgressClasses, Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { erc20Contract, web3 } from 'contracts/contract';
import { usePopup } from 'hooks';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { storageService } from 'services/storage';
import { CampTimer } from 'utils/campaign';
import { useSelector } from 'react-redux';
import { marketService } from 'services/market';

const whiteListUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLSdk1DElsqzJ27DZmvKTqJYajvGiBrV2JykXu74ZDW77VFraIA/viewform';

function WhitelistCard() {
  const { isLoggedIn, address } = useSelector(({ profile }) => profile);
  const [currency, setCurrency] = React.useState({
    name: 'USDT',
    index: 0,
  });
  const handClickCurrency = (event, index) => {
    setCurrency({ name: event.target.value, index: index });
  };

  //get TIme campaign
  const { data: time = {} } = useQuery(['marketService.fetchCampaignsTime'], () => marketService.fetchCampaignsTime(), {
    refetchOnMount: 'always',
    refetchInterval: 60 * 1000,
    staleTime: 0,
  });

  // start: time countdown
  const isStarted = useMemo(() => new Date(time.open_time).getTime() <= Date.now(), [time.open_time]);
  const isFinished = useMemo(() => new Date(time.close_time).getTime() <= Date.now(), [time.close_time]);
  const currentRound = useMemo(() => {
    if (!isStarted) return -1;
    else if (!isFinished) {
      if (new Date(time.fcfs_round_time).getTime() <= Date.now()) {
        return 3;
      } else if (new Date(time.double_limit_round_time).getTime() <= Date.now()) {
        return 2;
      } else {
        return 1;
      }
    }
    return 0;
  }, [time, isStarted, isFinished]);
  const times = useMemo(() => {
    switch (currentRound) {
      case -1: // not started
        return [new Date().toISOString(), time.open_time];
        break;
      case 0: // finished
        return [];
        break;
      case 1: // step 1
        return [time.open_time, time.double_limit_round_time];
        break;
      case 2: // step 2
        return [time.double_limit_round_time, time.fcfs_round_time];
        break;
      case 3: // step 3
        return [time.fcfs_round_time, time.close_time];
        break;
    }
  }, [time, currentRound]);
  const countdownLable = useMemo(() => {
    switch (currentRound) {
      case -1: // not started
        return 'Sale starts in';
      case 0: // finished
        return 'SALE HAS ENDED';
      case 1: // step 1
        return 'VIP Allocation round ends in';
      case 2: // step 2
        return 'VIP Double Quota round ends in';
      case 3: // step 3
        return 'FCFS (VIP access only) round ends in';
    }
  }, [time, currentRound]);
  // end: time countdown

  //get Quota of user level
  const { data: quota = {}, refetch: refetchQuota } = useQuery(
    ['marketService.getQuota'],
    () => marketService.getQuota(),
    {
      refetchOnMount: 'always',
      enabled: isLoggedIn,
      refetchInterval: 60 * 1000,
      staleTime: 0,
    },
  );

  const allocation = quota.allocated_amount / 10;
  const vipLevel = quota.name;
  const startTime = quota.can_buy_time;

  //get user allocation can buy
  const { data: amount = {}, refetch: refetchAmount } = useQuery(
    ['marketService.getMaxAmount'],
    () => marketService.getMaxAmount(),
    {
      refetchOnMount: 'always',
      enabled: isLoggedIn,
      refetchInterval: 60 * 1000,
      staleTime: 0,
    },
  );
  const userAllocation = amount;

  //get list Product
  const { data = [], refetch: refetchProduct } = useQuery(
    ['marketService.fetchProducts'],
    () => marketService.fetchProducts(),
    {
      refetchOnMount: 'always',
    },
  );

  const products = React.useMemo(() => {
    return data
      .sort((a, b) => a.product_id - b.product_id)
      .map((item) => {
        return {
          product_id: item?.product_id,
          prices: item?.product?.price,
          name: item?.product.name,
          remain_amount: item?.remain_quantity,
          amount: item?.product.amount,
        };
      });
  }, [data]);

  const amountLeft = products[0]?.remain_amount;
  const allowPerX = products[0]?.prices[0]?.price;

  //get product selected
  const product = React.useMemo(() => {
    let arr = products;
    return { ...arr[0] };
  }, [products]);
  //fetch new user level and product
  const onRefresh = () => {
    refetchQuota();
    refetchProduct();
    refetchAmount();
  };
  //amount quantity of item
  const [amounts, setAmounts] = React.useState();
  const setAmount = (value) => {
    setAmounts(value);
  };
  const totalPrice = amounts * allowPerX;

  //dialog
  const [isOpenCart, onOpenCart, onCloseCart] = usePopup(() => {
    let { createdAt, isContinue = false } = storageService.getOrder();
    if (createdAt) {
      const endTime = DateTime.fromISO(createdAt).plus({ minutes: 15 });
      isContinue = endTime > DateTime.now();
    }
    if (!isContinue) storageService.clearOrder();
    return isContinue;
  });
  const [isOpenDone, onOpenDone, onCloseDone] = usePopup();

  //checkout
  const currentPrice = product?.prices?.[currency.index];
  const { mutate: checkout } = useMutation(marketService.checkout, {
    onSuccess: (data, body) => {
      const { order } = data;
      storageService.saveOrder({
        orderId: order.id,
        createdAt: order.CreatedAt,
        ...body,
        discount: 0,
        totalDiscount: 0,
        currentPrice,
        totalPrice: totalPrice,
      });

      setTimeout(() => {
        onOpenCart();
        onRefresh();
      }, 0);
    },
  });
  const [isEnoughBalance, setIsEnoughBalance] = React.useState(true);

  const handleClickCheckout = async () => {
    const { payment_token } = currentPrice;
    const balance = payment_token?.address
      ? await erc20Contract(payment_token?.address).methods.balanceOf(address).call()
      : await web3.eth.getBalance(address);
    const isEnough = Number(balance) >= totalPrice * 10 ** (payment_token.decimal || 18);
    if (!isEnough && vipLevel !== 'VIP 0') {
      setIsEnoughBalance(false);
      setTimeout(() => {
        setIsEnoughBalance(true);
      }, 5000);
      return;
    }

    checkout({
      products: [
        {
          product_id: 1,
          amount: amounts * 10,
          name: 'ProductX',
        },
      ],
      currency: currency.name,
    });
  };

  //Tooltip
  const BundleTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(55, 46, 73, 0.8)',
        padding: '5px',
        border: '1px #624266 solid',
      },
    }),
  );

  return (
    <div>
      <div className='p-7 rounded-lg' style={{ backgroundColor: '#2C2C2E' }}>
        <div className='flex space-x-4 mb-4 justify-center xl:justify-start'>
          <img
            src={require('assets/projects/wizardia/ava.png').default}
            className='rounded-lg h-12 w-12 border-white border-4'
          />
          <div className=''>
            <div style={{ fontSize: '32px' }} className='text-white font-bold'>
              Wizardia IDO{' '}
            </div>
          </div>
        </div>
        <div className='flex justify-between my-8'>
          <span
            className='text-xl font-bold bg-clip-text text-transparent'
            style={{
              background:
                '-webkit-linear-gradient(90deg, rgba(255,130,130,1) 0%, rgba(255,196,79,1) 70%, rgba(255,81,0,1) 100%)',
            }}
          >
            Sale starts in
          </span>
        </div>
        <div className='flex flex-col w-full'>
          <Button href={whiteListUrl} target='_blank' className='h-14 text-base font-semibold'>
            GET WHITELISTED NOW
          </Button>
        </div>
      </div>
    </div>
  );
}

export default WhitelistCard;
