import React from 'react';
import { useSelector } from 'react-redux';
import { usePopup } from 'hooks';
import { Container, Dialog, Grid, LinearProgress, Typography } from '@mui/material';
import { WarningRounded } from '@mui/icons-material';
import { DesignButton } from 'components';
import { BoomiHeader } from 'views/Boomi/components';
import { PopupBuy, PopupCart, PopupDone } from 'views/Checkout';
import { formatNumber } from 'utils/common';
import { useQuery } from 'react-query';
import { storageService } from 'services/storage';
import { marketService } from 'services/market';
import { DateTime } from 'luxon';

const BoomiInventory = () => {
  const { isLoggedIn } = useSelector(({ profile }) => profile);

  const [isOpenBuy, onOpenBuy, onCloseBuy] = usePopup();
  const [isOpenCart, onOpenCart, onCloseCart] = usePopup(() => {
    let { createdAt, isContinue = false } = storageService.getOrder();
    if (createdAt) {
      const endTime = DateTime.fromISO(createdAt).plus({ minutes: 15 });
      isContinue = endTime > DateTime.now();
    }
    if (!isContinue) storageService.clearOrder();
    return isContinue;
  });
  const [isOpenDone, onOpenDone, onCloseDone] = usePopup();
  const [chosenItem, setChosenItem] = React.useState({});

  const { data: quota = {}, refetch: refetchQuota } = useQuery(
    ['marketService.getQuota'],
    () => marketService.getQuota(),
    { refetchOnMount: 'always', enabled: isLoggedIn },
  );

  const { data = [], refetch: refetchProduct } = useQuery(
    ['marketService.fetchProducts'],
    () => marketService.fetchProducts(),
    { refetchOnMount: 'always' },
  );

  const onRefresh = () => {
    refetchQuota();
    refetchProduct();
  };

  const products = React.useMemo(() => {
    return data
      .sort((a, b) => a.id - b.id)
      .map(({ product, ...item }) => {
        const payments = product.price.map((price) => price.payment_token);
        return {
          id: item.id,
          productId: item.product_id,
          payments,
          coins: payments.map((token) => token.symbol),
          remaining: item.remain_quantity,
          pool: product.amount,
          price: Number(product.price[0]?.price),
          discount: payments.map((token) => token.symbol).includes('SPO') ? 0.2 : 0,
          quota: quota.product_can_buy?.find((next) => next.id === item.product_id)?.remain_amount ?? 0,
        };
      });
  }, [data, quota]);

  return (
    <div className='text-white'>
      <div
        style={{
          background: `url(${require('assets/projects/boomi/background-inventory.png').default}) no-repeat`,
          backgroundPosition: 'center top',
          backgroundSize: 'cover',
        }}
      >
        <Container className='flex flex-col items-center py-12'>
          <BoomiHeader />
          <div style={{ maxWidth: 660 }}>
            <img src={require('assets/projects/boomi/box-egg-trans.png').default} className='-my-10' />
          </div>
          <Grid container spacing={3} justifyContent='center'>
            {products.map((item, index) => (
              <Grid item key={index} lg={4} md={6} xs={12} className='flex flex-col items-center'>
                <div
                  className='box-border relative flex flex-col items-center w-96 mx-3 p-10'
                  style={{ minWidth: 240 + 80 }}
                >
                  <div className='flex mb-2'>
                    {item.coins.map((coin, index) => (
                      <img
                        key={index}
                        src={require(`assets/icons/coin-${coin}.png`).default}
                        className={`z-${item.coins.length - index}0 -ml-${index > 0 ? 5 : 0}`}
                        title={coin}
                      />
                    ))}
                  </div>
                  <Typography variant='h4' align='center' className='mb-4'>
                    Buy with {item.coins.join('/')}
                  </Typography>
                  <Typography variant='subtitle2' className='mb-2'>
                    {`${formatNumber(item.remaining)}/${formatNumber(item.pool)} left`}
                  </Typography>
                  <LinearProgress
                    variant='determinate'
                    value={(item.remaining * 100) / item.pool}
                    style={{ width: 180, height: 8, borderRadius: 4, backgroundColor: '#1C0D3B' }}
                  />
                  <Typography variant='h1' color='primary' className='text-6xl md:text-7xl mt-4 mb-2'>
                    {formatNumber(item.price)}
                  </Typography>
                  <Typography variant='h4' color='primary' className='mb-8'>
                    {item.coins.join('/')}
                  </Typography>
                  <DesignButton
                    disabled={item.remaining === 0 || item.quota === 0}
                    onClick={() => {
                      onOpenBuy();
                      setChosenItem(item);
                    }}
                  >
                    {item.remaining > 0 ? 'BUY NOW' : 'SOLD OUT'}
                  </DesignButton>

                  {item.discount > 0 && (
                    <div
                      style={{ width: 120, background: `url(${require('assets/icons/coin-discount.png').default})` }}
                      className='absolute h-20 -right-6 flex flex-col items-center justify-center pb-3'
                    >
                      <Typography variant='h3'>-{item.discount * 100}%</Typography>
                      <Typography variant='subtitle1' className='relative'>
                        {formatNumber(item.price / (1 - item.discount))} {item.coins[0]}
                        <div className='absolute bg-white' style={{ height: 1, top: 15, width: '110%', left: '-5%' }} />
                      </Typography>
                    </div>
                  )}
                </div>

                {item.quota === 0 && (
                  <div className='rounded px-4 py-3 my-4' style={{ backgroundColor: '#FFAB00' }}>
                    <Typography variant='subtitle2' className='flex items-center' color='#253858'>
                      <WarningRounded />
                      You have reached your quota cap for this item.
                    </Typography>
                  </div>
                )}
              </Grid>
            ))}
          </Grid>
        </Container>
        <Dialog open={isOpenBuy} maxWidth='md'>
          <PopupBuy item={chosenItem} onClose={onCloseBuy} onSuccess={onOpenCart} onRefresh={onRefresh} />
        </Dialog>
        <Dialog open={isOpenCart} maxWidth='md'>
          <PopupCart onClose={onCloseCart} onSuccess={onOpenDone} onRefresh={onRefresh} />
        </Dialog>
        <Dialog open={isOpenDone} onClose={onCloseDone} maxWidth='md'>
          <PopupDone />
        </Dialog>
        <div className='h-20' />
      </div>
    </div>
  );
};

export default BoomiInventory;
