import React from 'react';
import { Grid, Link as MuiLink, Typography } from '@mui/material';
import { AnimaliaGuide, AnimaliaLeftCard } from 'views/Animalia/components';

const AniamaliaLanding = () => {
  return (
    <div className='text-white'>
      <div
        className='p-4 lg:p-20'
        style={{
          background: `url(${require('assets/projects/animalia/background-landing.png').default})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          paddingBottom: 180,
        }}
      >
        <Grid container spacing={4} className='justify-center items-start'>
          <Grid item xs={12} md={8} lg={4} xl={3.5} className='xl:sticky top-16'>
            <div
              style={{
                backgroundColor: 'rgba(55, 46, 73, 0.8)',
                boxShadow: '0px 0px 200px rgba(112, 0, 255, 0.3)',
                borderRadius: '8px',
              }}
              className='p-4 md:p-8 max-w-lg mx-auto'
            >
              <AnimaliaLeftCard />
            </div>
          </Grid>
          <Grid item md={12} lg={8} xl={8.5}>
            <div
              style={{
                backgroundColor: 'rgba(55, 46, 73, 0.8)',
                boxShadow: '0px 0px 200px rgba(112, 0, 255, 0.2)',
                borderRadius: '8px',
              }}
              className='p-4 md:p-8 justify-between max-w-screen-xl'
            >
              <img className='mb-8' src={require('assets/projects/animalia/Animalia1.jpg').default} />
              <div className='mb-4'>
                Animalia is an independent free-to-play online NFT trading card game featuring crypto-inspired meme
                creatures and gemstones. Powered by the Binance Smart Chain, Animalia gives you complete ownership over
                your in-game collectibles. Collect rare cards, create your own NFTs, build your deck, battle with other
                players and sell cards to other traders.
              </div>
              <div className='mb-8'>
                NFT Distribution: Airdropped by Animalia Team.{' '}
                <Typography component='span' color='primary'>
                  Items are chosen randomly.
                </Typography>
              </div>
              <div className='flex 2xl:flex-row flex-col 2xl:space-y-0 space-y-2  2xl:justify-between items-center mb-8'>
                <div>
                  <table style={{ backgroundColor: '#352C47' }}>
                    <tbody>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #624266' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/animalia/icons/tableIcon1.svg').default}
                            />
                            Network
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #624266' }}>
                          Binance Smart Chain (BSC)
                        </td>
                      </tr>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #624266' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/animalia/icons/tableIcon2.svg').default}
                            />
                            Start time
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #624266' }}>
                          3 January 2022 03:00 AM UTC
                        </td>
                      </tr>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #624266' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/animalia/icons/tableIcon3.svg').default}
                            />
                            End time
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #624266' }}>
                          4 January 2022 11:00 AM UTC
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table style={{ backgroundColor: '#352C47' }}>
                    <tbody>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #624266' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/animalia/icons/tableIcon4.svg').default}
                            />
                            Total amount
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #624266' }}>
                          150
                        </td>
                      </tr>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #624266' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/animalia/icons/tableIcon5.svg').default}
                            />
                            NFT type
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #624266' }}>
                          BEP-721
                        </td>
                      </tr>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #624266' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/animalia/icons/tableIcon6.svg').default}
                            />
                            Distribution network
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #624266' }}>
                          Binance Smart Chain (BSC)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <Grid className='flex flex-col'>
                <div
                  className='mb-12 self-center'
                  style={{ width: '50%', height: '1px', backgroundColor: '#624266' }}
                />
                <Typography variant='h1' className='self-center'>
                  Inventory
                </Typography>
                <div className='flex justify-center mt-4 mb-8'>
                  <img className='mx-1' src={require('assets/projects/animalia/icons/underlineIcon.svg').default} />
                  <img className='mx-1' src={require('assets/projects/animalia/icons/underlineIcon.svg').default} />
                  <img className='mx-1' src={require('assets/projects/animalia/icons/underlineIcon.svg').default} />
                </div>
                <img
                  className='self-center'
                  src={require('assets/projects/animalia/banner.png').default}
                  style={{ border: '10px solid black' }}
                />
                <Typography variant='h3' className='self-center my-4'>
                  Animalia Titans
                </Typography>
                <Typography
                  variant='h2'
                  className='self-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-300'
                >
                  870
                </Typography>
                <div className='self-center flex my-4'>
                  <div
                    className='px-1 rounded-full self-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-300 mx-1'
                    style={{ border: '1px solid #509CF5' }}
                  >
                    USDT
                  </div>
                  <div
                    className='px-1 rounded-full self-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-300 mx-1'
                    style={{ border: '1px solid #509CF5' }}
                  >
                    USDC
                  </div>
                  <div
                    className='px-1 rounded-full self-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-300 mx-1'
                    style={{ border: '1px solid #509CF5' }}
                  >
                    BUSD
                  </div>
                </div>
                <div className='self-center mb-12 text-base'> 150 items</div>
                <div
                  className='mb-12 self-center'
                  style={{ width: '50%', height: '1px', backgroundColor: '#624266' }}
                />
                <Typography variant='h1' className='self-center text-center'>
                  Project information
                </Typography>
                <div className='flex justify-center mt-4 mb-8'>
                  <img className='mx-1' src={require('assets/projects/animalia/icons/underlineIcon.svg').default} />
                  <img className='mx-1' src={require('assets/projects/animalia/icons/underlineIcon.svg').default} />
                  <img className='mx-1' src={require('assets/projects/animalia/icons/underlineIcon.svg').default} />
                </div>
                <img className='self-center' src={require('assets/projects/animalia/projectInfo.jpg').default} />
                <Typography
                  variant='h1'
                  fontFamily='Cinzel Decorative'
                  className='self-center mt-6 text-transparent bg-clip-text'
                  style={{
                    background:
                      '-webkit-linear-gradient(180deg, #F0E1BB 0%, #C0A96B 70%, #DDCEA8 100%, #F1D792 70%, #AB9969 100%)',
                  }}
                >
                  Animalia
                </Typography>
                <div className='flex flex-wrap self-center my-6 -px-1 -mx-1'>
                  {[
                    {
                      url: 'https://animalia.games',
                      name: 'Official Website',
                      image: require('assets/icons/media/website.svg').default,
                    },
                    {
                      url: 'https://t.me/Animalia_ANIM',
                      name: 'Telegram',
                      image: require('assets/icons/media/telegram.svg').default,
                    },
                    {
                      url: 'https://twitter.com/Animalia_token',
                      name: 'Twitter',
                      image: require('assets/icons/media/twitter.svg').default,
                    },
                    {
                      url: 'https://medium.com/animaliaanim',
                      name: 'Medium',
                      image: require('assets/icons/media/medium.svg').default,
                    },
                    {
                      url: 'https://www.reddit.com/r/Animalia_ANIM',
                      name: 'Reddit',
                      image: require('assets/icons/media/reddit.svg').default,
                    },
                    {
                      url: 'https://github.com/AnimaliaANIM',
                      name: 'Github',
                      image: require('assets/icons/media/github.svg').default,
                    },
                    {
                      url: 'https://www.youtube.com/c/AnimaliaANIM',
                      name: 'Youtube',
                      image: require('assets/icons/media/youtube.svg').default,
                    },
                    {
                      url: 'https://instagram.com/animalia_token',
                      name: 'Instagram',
                      image: require('assets/icons/media/instagram.svg').default,
                    },
                  ].map((item, index) => (
                    <MuiLink
                      key={index}
                      href={item.url}
                      className='flex items-center w-7 text-gray-200 hover:text-white mx-1 md:mx-4 opacity-50 hover:opacity-100'
                      title={item.name}
                    >
                      <img className='fill-current' src={item.image} />
                    </MuiLink>
                  ))}
                </div>
                <div>
                  Animalia is an independent free-to-play online NFT trading card game featuring crypto-inspired meme
                  creatures and gemstones. Powered by the Binance Smart Chain, Animalia gives you complete ownership
                  over your in-game collectibles. Collect rare cards, create your own NFTs, build your deck, battle with
                  other players and sell cards to other traders.
                </div>
                <Typography
                  variant='h1'
                  fontFamily='Cinzel Decorative'
                  className='self-center my-6 text-transparent bg-clip-text'
                  style={{
                    background:
                      '-webkit-linear-gradient(180deg, #F0E1BB 0%, #C0A96B 70%, #DDCEA8 100%, #F1D792 70%, #AB9969 100%)',
                  }}
                >
                  Titans
                </Typography>
                <div>
                  In the Animalia Kingdom, the world is divided into two factions, each owned by a Titan - the fearsome
                  Bull and the mighty Bear. Titans can be classified into different “species”, each with its own unique
                  abilities and powers. Non-fungible Titans (NFTitan) as an initial series of NFTs will be deployed in a
                  BEP-721 smart contract. Each NFTitan is guaranteed to be unique in the collection. As a start, Toro
                  and Ursa will be released as the first two Founders Edition NFTitans in this upcoming Private INO.
                </div>
                <Grid container spacing={3} justifyContent='center' className='my-8 self-center max-w-screen-xl'>
                  {[
                    {
                      name: 'Ursa',
                      description: `Prepared contenders of unrivaled
                    ability, Ursa have dominance over a
                    stockpile of weaponry and protection,
                    permitting them to be both dangerous
                    soldiers and impressive safeguards.
                    Taking harm just serves to incense
                    Ursa and his Minions, setting off
                    amazing impacts that further improve
                    their fearsome capacities. Ursa
                    features its ability to wield varieties of
                    martial tools with deadly alacrity.`,
                      image: require('assets/projects/animalia/ursa.png').default,
                    },
                    {
                      name: 'Toro',
                      description: `No other Titans uses the obscure with
                    as much expertise and crude power as
                    a Toro. Utilizing their unmatched cluster
                    of spells, Toro can wipe entire boards
                    of critters, deal destructive amounts
                    of damage directly to their enemies, or
                    gather unadulterated energy. Expertise
                    in enchanted powers used by Toro
                    have astonishing outcomes. They
                    take advantage of nature magic by
                    activating powerful effects.`,
                      image: require('assets/projects/animalia/toro.png').default,
                    },
                  ].map((item, index) => (
                    <Grid key={index} item md={6} xs={12}>
                      <div
                        className='rounded-lg flex flex-col  h-full items-center'
                        style={{ border: '2px solid #624266' }}
                      >
                        <div className='flex mt-4 '>
                          <img src={item.image} className='mx-auto mb-3' />
                        </div>
                        <Grid container>
                          <Grid item className='m-4'>
                            <div className='text-center md:text-left mb-3'>
                              <Typography variant='h2'>{item.name}</Typography>
                            </div>
                            <Typography align='justify' style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                              {item.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  ))}
                </Grid>
                <img className='self-center mb-6' src={require('assets/projects/animalia/info1.jpg').default} />
                <img className='self-center mb-6' src={require('assets/projects/animalia/info2.jpg').default} />
                <img className='self-center' src={require('assets/projects/animalia/info3.jpg').default} />
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div columns={12} className='mt-10'>
          <AnimaliaGuide />
        </div>
      </div>
    </div>
  );
};

export default AniamaliaLanding;
