import React from 'react';
import { useQuery } from 'react-query';
import { Container, Table, TableHead, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { marketService } from 'services/market';
import { DateTime } from 'luxon';
import { shorten } from 'utils/common';

const STATUS_MAP = {
  1: { name: 'PENDING', color: '#FF991F' },
  2: { name: 'PENDING', color: '#FF991F' },
  3: { name: 'SUCCESS', color: '#00875A' },
  4: { name: 'FAILED', color: '#DE350B' },
};

const BoomiOrder = () => {
  const { data: dataList = [] } = useQuery(['marketService.searchSales'], () => marketService.fetchOrders());

  const { data: products = [] } = useQuery(['marketService.fetchProducts'], () => marketService.fetchProducts());
  const paymentTokens = products.reduce(
    (payments, item) =>
      payments.concat(
        item.product.price.map((payment) => ({
          ...payment.payment_token,
          price: Number(payment.price),
        })),
      ),
    [],
  );

  return (
    <Container className='py-10'>
      <div className='box-border p-8'>
        <Typography variant='h3' color='#E0C685' className='mb-6'>
          ORDER HISTORY
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>TRANSACTION ID</TableCell>
              <TableCell>DATE</TableCell>
              <TableCell>ITEM</TableCell>
              <TableCell>PRICE PER BOX</TableCell>
              <TableCell>QUANTITY</TableCell>
              <TableCell>TOTAL PRICE</TableCell>
              <TableCell>STATUS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((item, index) => {
              item.price = paymentTokens.find((next) => next.symbol === item.currency)?.price;
              return (
                <TableRow key={index}>
                  <TableCell>
                    <span title={item.transaction_hash}>{shorten(item.transaction_hash, 12, 10)}</span>
                  </TableCell>
                  <TableCell>{DateTime.fromISO(item.order_time).toFormat('HH:mm dd/MM/yyyy')}</TableCell>
                  <TableCell>Super BOOMi Loot box</TableCell>
                  <TableCell>
                    {item.price} {item.currency}
                  </TableCell>
                  <TableCell>{item.total_amount / item.price}</TableCell>
                  <TableCell>
                    {item.total_amount} {item.currency}
                  </TableCell>
                  <TableCell>
                    <span
                      className='text-white text-sm rounded px-4 py-1.5'
                      style={{ backgroundColor: STATUS_MAP[item.status].color }}
                    >
                      {STATUS_MAP[item.status].name}
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Container>
  );
};

export default BoomiOrder;
