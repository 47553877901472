import { Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

const PopupDone = () => {
  return (
    <div className='box-border text-center text-white px-20'>
      <CheckCircle className='text-7xl text-green-500' />
      <Typography variant='h3' className='my-2'>
        Thank you!
      </Typography>
      <Typography variant='body2' color='silver'>
        Your submission has been recorded. We are processing your order.
      </Typography>
    </div>
  );
};

export default PopupDone;
