import { Grid, Typography } from '@mui/material';
import React from 'react';
import { DinoXGuide, DinoXLeftCard } from 'views/DinoX/components';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const DinoXLanding = () => {
  const [item, setItem] = React.useState(0);
  const handleClickCard = (name) => {
    setItem(name);
  };
  const BundleTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#48484A',
        width: '800px',
      },
    }),
  );
  return (
    <div className='text-white'>
      <div
        className='p-4 lg:p-20 flex flex-col items-center justify-center'
        style={{
          background: `url(${require('assets/projects/animalia/background-landing.png').default})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          paddingBottom: 180,
        }}
      >
        <Grid
          container
          spacing={4}
          className='flex justify-center items-start'
          style={{
            maxWidth: '1500px',
          }}
        >
          <Grid item xs={12} md={12} lg={3.5} xl={3.5} className='xl:sticky top-16'>
            <div
              style={{
                backgroundColor: '#2C2C2E',
                borderRadius: '8px',
              }}
              className='p-4 md:p-8 xl:max-w-lg lg:w-full mx-auto'
            >
              <DinoXLeftCard nameItem={item} />
            </div>
          </Grid>
          <Grid item md={12} lg={8} xl={8.5}>
            <div
              style={{
                backgroundColor: '#2C2C2E',
                borderRadius: '8px',
              }}
              className='p-4 md:p-8 justify-between max-w-screen-xl'
            >
              <img className='mb-8 w-full' src={require('assets/projects/dinoX/Animalia x banner 1.png').default} />
              <div className='mb-4'>
                DINOX World is a combat and strategy game in a sandbox environment. A beloved gameplay genre blended
                with a fan-favorite theme of dinosaurs. Players can own their own dinosaurs (NFTs) and train, breed,
                upgrade, and battle against AI and player opponents. Players earn rewards for accomplishments,
                checkpoints, and battle victories as part of a Play to Earn model.
              </div>
              <div className='flex 2xl:flex-row flex-col 2xl:space-y-0 space-y-2  2xl:justify-between items-center mb-8'>
                <div>
                  <table style={{ backgroundColor: 'transparent' }}>
                    <tbody>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #8E8E93' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/dinoX/icons/tableIcon1.svg').default}
                            />
                            Network
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #8E8E93' }}>
                          Binance Smart Chain (BSC)
                        </td>
                      </tr>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #8E8E93' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/dinoX/icons/tableIcon2.svg').default}
                            />
                            Start time
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #8E8E93' }}>
                        03 March 2022 01:00 PM UTC
                        </td>
                      </tr>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #8E8E93' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/dinoX/icons/tableIcon3.svg').default}
                            />
                            End time
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #8E8E93' }}>
                        06 March 2022 01:00 PM UTC
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table style={{ backgroundColor: '#transparent' }}>
                    <tbody>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #8E8E93' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/dinoX/icons/tableIcon4.svg').default}
                            />
                            Total amount
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #8E8E93' }}>
                          325
                        </td>
                      </tr>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #8E8E93' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/dinoX/icons/tableIcon5.svg').default}
                            />
                            NFT type
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #8E8E93' }}>
                          ERC-1155
                        </td>
                      </tr>
                      <tr>
                        <td className='border h-10 w-52 px-3' style={{ border: '1px solid #8E8E93' }}>
                          <div className='flex'>
                            <img
                              className='mr-2'
                              style={{ width: '20px', height: '22px' }}
                              src={require('assets/projects/dinoX/icons/tableIcon6.svg').default}
                            />
                            Distribution network
                          </div>
                        </td>
                        <td className='border h-10 w-64 px-3' style={{ border: '1px solid #8E8E93' }}>
                          Polygon
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <Grid className='flex flex-col'>
                <Typography variant='h1' className='self-center mb-6'>
                  Inventory
                </Typography>
                <img className='w-full mb-4' src={require('assets/projects/dinoX/inventory.png').default}></img>
                <div className='font-bold' style={{ fontSize: '24px' }}>
                  Bundles
                </div>
                <Grid container spacing={2} className='mt-1 mb-16 xl:justify-start lg:justify-center'>
                  {[
                    {
                      bundle: require('assets/projects/dinoX/inventory/Bundle 1.png').default,
                      name: 'Bundle 1',
                      remain: '10 items',
                      priceBefore: '$960',
                      price: `$860`,
                      discount: '-10%',
                      item1: 'Rare Land 2x2',
                      item2: 'Uncommon Land 1x1',
                      item3: 'Common Land 1x1',
                      img1: require('assets/projects/dinoX/inventory/Rare 2x2.png').default,
                      img2: require('assets/projects/dinoX/inventory/Uncommon 1x1.png').default,
                      img3: require('assets/projects/dinoX/inventory/Common 1x1.png').default,
                      amount1: '1',
                      amount2: '1',
                      amount3: '2',
                    },
                    {
                      bundle: require('assets/projects/dinoX/inventory/Bundle 2.png').default,
                      name: 'Bundle 2',
                      remain: '5 items',
                      priceBefore: '$1,610',
                      price: `$1,450`,
                      discount: '-10%',
                      item1: 'Legendary Land 3x3',
                      item2: 'Rare Land 2x2',
                      item3: 'Uncommon Land 2x2',
                      img1: require('assets/projects/dinoX/inventory/Legendary 3x3.png').default,
                      img2: require('assets/projects/dinoX/inventory/Rare 2x2.png').default,
                      img3: require('assets/projects/dinoX/inventory/Uncommon 2x2.png').default,
                      amount1: '1',
                      amount2: '1',
                      amount3: '2',
                    },
                    {
                      bundle: require('assets/projects/dinoX/inventory/Bundle 3.png').default,
                      name: 'Bundle 3',
                      remain: '3 items',
                      priceBefore: '$2,370',
                      price: `$2,140`,
                      discount: '-10%',
                      item1: 'Mythical Land 4x4',
                      item2: 'Legendary Land 4x4',
                      item3: 'Rare Land 3x3',
                      img1: require('assets/projects/dinoX/inventory/Mythic 4x4.png').default,
                      img2: require('assets/projects/dinoX/inventory/Legendary 4x4.png').default,
                      img3: require('assets/projects/dinoX/inventory/Rare 3x3.png').default,
                      amount1: '1',
                      amount2: '1',
                      amount3: '1',
                    },
                  ].map((item, index) => (
                    <Grid item key={index} lg={6} md={8} xs={12}>
                      <BundleTooltip
                        title={
                          <React.Fragment>
                            <div className='m-2'>
                              <div className='flex justify-between'>
                                <div className='font-bold' style={{ fontSize: '18px' }}>
                                  {item.name}
                                </div>
                                <div
                                  className='font-thin'
                                  style={{ fontSize: '14px', color: 'rgba(255, 255, 255, 0.6)' }}
                                >
                                  {item.remain}
                                </div>
                              </div>
                              <div className='w-full' style={{ height: '1px', backgroundColor: '#ffffff' }} />
                              <div className='flex items-center my-4'>
                                <div>
                                  <Typography
                                    className='relative'
                                    color='rgba(255, 255, 255, 0.6)'
                                    style={{ fontSize: '16px' }}
                                  >
                                    {item.priceBefore}
                                    <div
                                      className='absolute bg-gray-400'
                                      style={{ height: 1, top: 10, width: '110%', left: '-5%' }}
                                    />
                                  </Typography>
                                </div>
                                <Typography
                                  className='text-transparent bg-clip-text font-bold ml-2'
                                  whiteSpace='pre-line'
                                  align='justify'
                                  style={{
                                    fontSize: '30px',
                                    background: '-webkit-linear-gradient(225deg, #FFEF5E 0%, #F7936F 100%)',
                                  }}
                                >
                                  {item.price}
                                </Typography>
                              </div>
                              <div
                                className='mb-2'
                                style={{ fontWeight: '400', color: 'rgba(255, 255, 255, 0.7)', fontSize: '14px' }}
                              >
                                You will get all the lands inside
                              </div>
                              <div className='px-2 py-2' style={{ backgroundColor: '#636366', borderRadius: '8px' }}>
                                <div className='flex justify-between items-center'>
                                  <div className='flex items-center'>
                                    <img className='mb-2' src={item.img1} style={{ maxWidth: '40px' }} />
                                    <div className='ml-2'>{item.item1}</div>
                                  </div>
                                  <div
                                    className='font-thin'
                                    style={{ fontSize: '14px', color: 'rgba(255, 255, 255, 0.6)' }}
                                  >
                                    x{item.amount1}
                                  </div>
                                </div>
                                <div className='flex justify-between items-center'>
                                  <div className='flex items-center'>
                                    <img className='mb-2' src={item.img2} style={{ maxWidth: '40px' }} />
                                    <div className='ml-2'>{item.item2}</div>
                                  </div>
                                  <div
                                    className='font-thin'
                                    style={{ fontSize: '14px', color: 'rgba(255, 255, 255, 0.6)' }}
                                  >
                                    x{item.amount2}
                                  </div>
                                </div>
                                <div className='flex justify-between items-center'>
                                  <div className='flex items-center'>
                                    <img src={item.img3} style={{ maxWidth: '40px' }} />
                                    <div className='ml-2'>{item.item3}</div>
                                  </div>
                                  <div
                                    className='font-thin'
                                    style={{ fontSize: '14px', color: 'rgba(255, 255, 255, 0.6)' }}
                                  >
                                    x{item.amount3}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        }
                      >
                        <div
                          className='p-3 flex'
                          style={{ borderRadius: '8px', backgroundColor: '#3A3A3C' }}
                          onClick={() => handleClickCard(item.name)}
                        >
                          <div>
                            <img
                              src={item.bundle}
                              className='mx-auto mb-3 md:mb-0 md:ml-0 md:mr-6'
                              style={{ width: '72px' }}
                            />
                          </div>
                          <div className='flex-1'>
                            <div className='flex justify-between items-end md:text-left'>
                              <div
                                className='font-bold'
                                style={{
                                  fontSize: '18px',
                                }}
                              >
                                {item.name}
                              </div>
                              <div
                                style={{
                                  fontSize: '14px',
                                  color: '#A0AEC0',
                                }}
                              >
                                {item.remain}
                              </div>
                            </div>
                            <div className='flex items-center mt-2'>
                              <div>
                                <Typography
                                  className='relative'
                                  color='rgba(255, 255, 255, 0.6)'
                                  style={{ fontSize: '12px' }}
                                >
                                  {item.priceBefore}
                                  <div
                                    className='absolute bg-gray-400'
                                    style={{ height: 1, top: 10, width: '110%', left: '-5%' }}
                                  />
                                </Typography>
                              </div>
                              <Typography
                                className='text-transparent bg-clip-text font-bold ml-2'
                                whiteSpace='pre-line'
                                align='justify'
                                style={{
                                  fontSize: '18px',
                                  background: '-webkit-linear-gradient(225deg, #FFEF5E 0%, #F7936F 100%)',
                                }}
                              >
                                {item.price}
                              </Typography>
                              <div
                                className='bg-red-600 ml-2 px-1 py-0.5'
                                style={{ fontSize: '12px', borderRadius: '3px' }}
                              >
                                {item.discount}
                              </div>
                            </div>
                          </div>
                        </div>
                      </BundleTooltip>
                    </Grid>
                  ))}
                </Grid>
                <div className='font-bold' style={{ fontSize: '24px' }}>
                  Lands
                </div>
                <Grid container spacing={2} justifyContent='center' className='mt-1 mb-16'>
                  {[
                    {
                      video: require('assets/projects/dinoX/inventory/Mythic 4x4.mp4').default,
                      name: 'Mythic 4x4',
                      remain: '5 items',
                      price: `$1,190`,
                    },
                    {
                      video: require('assets/projects/dinoX/inventory/Legendary 4x4.mp4').default,
                      name: 'Legendary 4x4',
                      remain: '5 items',
                      price: `$790`,
                    },
                    {
                      video: require('assets/projects/dinoX/inventory/Legendary 3x3.mp4').default,
                      name: 'Legendary 3x3',
                      remain: '7 items',
                      price: `$690`,
                    },
                    {
                      video: require('assets/projects/dinoX/inventory/Rare 3x3.mp4').default,
                      name: 'Rare 3x3',
                      remain: '30 items',
                      price: `$390`,
                    },
                    {
                      video: require('assets/projects/dinoX/inventory/Rare 2x2.mp4').default,
                      name: 'Rare 2x2',
                      remain: '50 items',
                      price: `$340`,
                    },
                    {
                      video: require('assets/projects/dinoX/inventory/Uncommon 2x2.mp4').default,
                      name: 'Uncommon 2x2',
                      remain: '50 items',
                      price: `$290`,
                    },
                    {
                      video: require('assets/projects/dinoX/inventory/Uncommon 1x1.mp4').default,
                      name: 'Uncommon 1x1',
                      remain: '80 items',
                      price: `$240`,
                    },
                    {
                      video: require('assets/projects/dinoX/inventory/Common 1x1.mp4').default,
                      name: 'Common 1x1',
                      remain: '80 items',
                      price: `$190`,
                    },
                  ].map((item, index) => (
                    <Grid item key={index} lg={6} md={8} xs={12}>
                      <div
                        className='p-3 flex'
                        style={{ borderRadius: '8px', backgroundColor: '#3A3A3C' }}
                        onClick={() => handleClickCard(item.name)}
                      >
                        <div>
                          <video
                            src={item.video}
                            className='mx-auto mb-3 md:mb-0 md:ml-0 md:mr-6'
                            autoPlay
                            loop
                            muted
                            style={{ width: '72px' }}
                          />
                        </div>
                        <div className='flex-1'>
                          <div className='flex justify-between items-end md:text-left'>
                            <div
                              className='font-bold'
                              style={{
                                fontSize: '18px',
                              }}
                            >
                              {item.name}
                            </div>
                            <div
                              style={{
                                fontSize: '14px',
                                color: '#A0AEC0',
                              }}
                            >
                              {item.remain}
                            </div>
                          </div>
                          <Typography
                            className='text-transparent bg-clip-text font-bold mt-2'
                            whiteSpace='pre-line'
                            align='justify'
                            style={{
                              fontSize: '18px',
                              background: '-webkit-linear-gradient(225deg, #FFEF5E 0%, #F7936F 100%)',
                            }}
                          >
                            {item.price}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
                <Typography variant='h1' className='self-center text-center mb-8'>
                  Project information
                </Typography>
                <img className='self-center w-full' src={require('assets/projects/dinoX/info1.png').default} />
                <Typography
                  className='self-center mt-6 font-bold mb-4'
                  style={{
                    fontSize: '32px',
                  }}
                >
                  Play to Earn Model
                </Typography>
                <div>
                  DINOX World uses a Play to Earn model, rewarding players with lucrative rewards in the form of DNXI
                  (in-game items like resources or action cards) as well as DNXC and even the possibility to earn DNX
                  dinosaurs.
                </div>
                <Typography
                  className='self-center mt-6 font-bold mb-4'
                  style={{
                    fontSize: '32px',
                  }}
                >
                  Highlight Features
                </Typography>
                <div className='mb-4'>
                  DNX dinosaur NFTs - Train, breed, upgrade, and battle with your own dinosaur NFT. As you progress
                  through the game, you can upgrade your dinosaur, teach them new skills, and increase their combat
                  effectiveness. Currently Generation 0 dinos are already being used in the Alpha version of the game
                  and Generation 1 will be distributed to stakers and contest winners around Dec. 18th.
                </div>
                <div className='mb-4'>
                  Islands - Essentially your dinosaur kingdom. This is where you can train and upgrade your dinosaurs,
                  as well as prepare your teams for battles, build and upgrade various buildings and prepare for battles
                  and adventures.
                </div>
                <div>
                  In-game items - Action Cards are the main in-game item and are used as your dinosaur’s main skills in
                  battle. Resource Cards are a key element for upgrading your dinosaurs, improving dinosaur
                  effectiveness, and expanding your dinosaur kingdom.
                </div>
                <Typography
                  className='self-center mt-6 font-bold mb-4'
                  style={{
                    fontSize: '32px',
                  }}
                >
                  Product Viability
                </Typography>
                <div className='mb-4'>
                  The full DinoX universe is a well-planned project made by a team of founders and advisors with
                  expertise in blockchain and, most importantly, games.
                </div>
                <div className='mb-4'>
                  All of the pillars of this project are designed so that the whole experience could resonate within the
                  community and potentially bring into this blockchain world, other people that may be hesitating
                  because of complicated terms and volatile media posts:
                </div>
                <div className='mb-4 ml-6'>
                  <ul className='list-disc'>
                    <li>The aspect of Dinosaurs targets as wide an audience as possible </li>
                    <li>The aspect of Play to Earn targets more than just leisure players</li>
                    <li>
                      The aspect of Staking and Governing targets people who like to play in the upper layers of
                      ecosystems
                    </li>
                    <li>
                      And the aspect of Community is a building block of all successful projects, so we will work
                      especially hard on this
                    </li>
                  </ul>
                </div>
                <img className='self-center mb-6 w-full' src={require('assets/projects/dinoX/info2.png').default} />
                <img className='self-center w-full' src={require('assets/projects/dinoX/info3.png').default} />
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div
          columns={12}
          className='flex justify-center items-start mt-10'
          style={{
            maxWidth: '1500px',
          }}
        >
          <DinoXGuide />
        </div>
      </div>
    </div>
  );
};

export default DinoXLanding;
