import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { AppFooter, AppHeader, AppPreHeader } from 'containers';
import { privateRoute } from 'routes';

const PrivateLayout = () => {
  const { isLoggedIn } = useSelector(({ profile }) => profile);
  const location = useLocation();
  return (
    <main className='min-h-screen flex flex-col'>
      {location.pathname.startsWith(privateRoute.home.path) && <AppPreHeader />}
      <AppHeader />
      <div className='flex-1' style={{ backgroundColor: '#0A0C10' }}>
        <Switch>
          {Object.values(privateRoute)
            .filter(({ requiredLogin }) => (requiredLogin ? isLoggedIn : true))
            .map(({ path, component }) => (
              <Route exact key={path} path={path} component={component} />
            ))}
          <Redirect from='/' to={privateRoute.home.path} />
        </Switch>
      </div>
      <AppFooter />
    </main>
  );
};

export default PrivateLayout;
