import { Redirect, Route, Switch } from 'react-router';
import { BoomiOrder } from 'views/Boomi';
import { McGeeOrder } from 'views/McGee';
import { AnimaliaOrder } from 'views/Animalia';
import { DinoXOrder } from 'views/DinoX';
import { BitHotelOrder } from 'views/BitHotel';
import { RitestreamOrder } from 'views/Ritestream';
import { privateRoute } from 'routes';

const Order = () => {
  return (
    <Switch>
      <Route path={privateRoute.projectOrder.urlBoomi} component={BoomiOrder} />
      <Route path={privateRoute.projectOrder.urlMcgee} component={McGeeOrder} />
      <Route path={privateRoute.projectOrder.urlAnimalia} component={AnimaliaOrder} />
      <Route path={privateRoute.projectOrder.urlDinoX} component={DinoXOrder} />
      <Route path={privateRoute.projectOrder.urlBitHotel} component={BitHotelOrder} />
      <Route path={privateRoute.projectOrder.urlRitestream} component={RitestreamOrder} />
      <Redirect from='/' to={privateRoute.home.path} />
    </Switch>
  );
};

export default Order;
