import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { usePopup } from 'hooks';
import { DesignButton } from 'components';
import { Container, Hidden, Grid, Typography, Dialog } from '@mui/material';
import { WarningRounded } from '@mui/icons-material';
import { signIn } from 'reducers/profileSlice';
import { connectWallet } from 'reducers/profileAction';
import { marketService } from 'services/market';
import { storageService } from 'services/storage';
import { CampTimer } from 'utils/campaign';
import { formatNumber } from 'utils/common';
import { DateTime } from 'luxon';
import { McGeeHeader } from 'views/McGee/components';
import { PopupBuy, PopupCart, PopupDone } from 'views/Checkout';

const McGeeLanding = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, sale_start } = useSelector(({ profile }) => profile);

  const [{ isStarted, isFinished }, setTimer] = React.useState(CampTimer);

  const [isOpenBuy, onOpenBuy, onCloseBuy] = usePopup();
  const [isOpenCart, onOpenCart, onCloseCart] = usePopup(() => {
    let { createdAt, isContinue = false } = storageService.getOrder();
    if (createdAt) {
      const endTime = DateTime.fromISO(createdAt).plus({ minutes: 15 });
      isContinue = endTime > DateTime.now();
    }
    if (!isContinue) storageService.clearOrder();
    return isContinue;
  });
  const [isOpenDone, onOpenDone, onCloseDone] = usePopup();
  const [chosenIndex, setChosenIndex] = React.useState(0);

  const { data: quota = {}, refetch: refetchQuota } = useQuery(
    ['marketService.getQuota'],
    () => marketService.getQuota(),
    {
      refetchOnMount: 'always',
      enabled: isLoggedIn,
      refetchInterval: 60 * 1000,
      staleTime: 0,
    },
  );
  const remainUser = quota.product_can_buy?.[0]?.remain_amount || 0;
  const canBuy = isStarted && !isFinished && remainUser > 0;

  const { data = [], refetch: refetchProduct } = useQuery(
    ['marketService.fetchProducts'],
    () => marketService.fetchProducts(),
    { refetchOnMount: 'always' },
  );

  React.useEffect(() => {
    if (isLoggedIn) {
      const { discount, min_discount_quantity } = quota;
      dispatch(signIn({ discount, min_discount_quantity }));
    }
  }, [dispatch, isLoggedIn, quota]);

  const onRefresh = () => {
    refetchQuota();
    refetchProduct();
  };

  const products = React.useMemo(() => {
    return data.map((item) => {
      const prices = item.product.price_with_amount;
      return {
        product_id: item.product_id,
        prices,
        name: item.product.name,
      };
    });
  }, [data]);

  React.useEffect(() => {
    setTimer(() => CampTimer({ saleStart: sale_start }));
  }, [sale_start]);

  React.useEffect(() => {
    if (!isLoggedIn) {
      onCloseBuy();
      onCloseCart();
      onCloseDone();
    }
  }, [isLoggedIn, onCloseBuy, onCloseCart, onCloseDone]);

  return (
    <div className='text-white'>
      <div
        style={{
          background: `url(${require('assets/projects/americanmcgee/background-landing.png').default}) no-repeat`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          paddingBottom: 180,
        }}
      >
        <Container className='flex flex-col items-center py-12'>
          <Grid container columns={2}>
            <Grid item>
              <McGeeHeader />
            </Grid>
            <Grid item>
              <img
                src={require('assets/projects/americanmcgee/banner.png').default}
                className='mx-auto rounded-lg sm:rounded-none'
              />
            </Grid>
          </Grid>
        </Container>
      </div>

      <Container>
        <div className='items-center  pt-12'>
          <Grid container spacing={3} justifyContent='center' className='mb-16' style={{ marginTop: -240 }}>
            {[
              {
                name: 'Anxiety Rabbit',
                description: `Whisper all the troubles in the world and then zip them away inside the Anxiety Rabbit! As time
                passes towards the next Full Moon, all those worries will gradually fade away.`,
                video: require('assets/projects/americanmcgee/checkout-video1.mp4').default,
              },
              {
                name: 'Goth Rabbit',
                description: `It's midnight and the rain-slick streets are lit by a harvest moon. Goth Rabbit lights a clove
              from her bow-shaped black purse and walks on 6" stiletto boots towards the Crystal Palace Bowl.
              It's going to be another one of those nights.`,
                video: require('assets/projects/americanmcgee/checkout-video2.mp4').default,
              },
            ].map((item, index) => (
              <Grid key={index} item md={6} xs={12}>
                <div className='box-border flex flex-col md:items-start h-full' style={{ border: '2px solid #8543E6' }}>
                  <Hidden smDown>
                    <video
                      src={item.video}
                      className='mx-auto mb-3 md:mb-0 md:ml-0 md:mr-6 rounded'
                      autoPlay
                      loop
                      muted
                    />
                  </Hidden>
                  <Grid container className='mt-4'>
                    <Grid item className='m-4'>
                      <div className='text-center md:text-left mb-3'>
                        <Typography variant='h2' fontFamily='Orbitron'>
                          {item.name}
                        </Typography>
                      </div>
                      <Typography align='justify'>{item.description}</Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>

        <Container className='flex justify-center content-center p-10' style={{ background: '#0C0C0C' }}>
          {products.map((product, index) => {
            const price = product.prices[index];
            const remain = products.reduce((count, product) => count + product.prices[index].remain, 0);
            const amount = products.reduce((count, product) => count + product.prices[index].amount, 0);

            return (
              <div
                key={index}
                className='relative flex flex-col items-center mx-4 p-10'
                style={{ minWidth: 240 + 80, background: '#1B1B1B', borderRadius: '16px', width: '405px' }}
              >
                <Typography variant='h4' align='center' className='my-4'>
                  Buy with {price.payment_token.symbol}
                </Typography>
                <div className='h-10'>
                  {price.discount !== 0 && (
                    <Typography variant='h4' className='relative h-10' color='rgba(255, 255, 255, 0.6)'>
                      {formatNumber(price.price / (1 - price.discount))}
                      <div
                        className='absolute bg-gray-400'
                        style={{ height: 1, top: 14, width: '110%', left: '-5%' }}
                      />
                    </Typography>
                  )}
                </div>
                <Typography variant='h1' className='text-6xl md:text-7xl mt-4 mb-10'>
                  {formatNumber(price.price)}
                </Typography>
                <DesignButton
                  disabled={!canBuy || remain === 0}
                  onClick={() => {
                    if (isLoggedIn) {
                      onOpenBuy();
                      setChosenIndex(index);
                    } else {
                      connectWallet();
                    }
                  }}
                >
                  {remain > 0 ? 'BUY NOW' : 'SOLD OUT'}
                </DesignButton>
                <Typography variant='subtitle2' className='mt-4 text-base'>
                  Remaining items: {`${formatNumber(remain)}/${formatNumber(amount)}`}
                </Typography>

                {price.discount !== 0 && (
                  <div
                    style={{ width: 120 }}
                    className='absolute h-16 -right-6 flex flex-col items-center justify-center bg-red-600'
                  >
                    <Typography variant='h3'>-{formatNumber(price.discount * 100)}%</Typography>
                  </div>
                )}
              </div>
            );
          })}
        </Container>
        {isLoggedIn && isStarted && remainUser === 0 && (
          <div className='flex justify-center'>
            <div className='rounded px-4 py-3 my-4' style={{ backgroundColor: '#FFAB00' }}>
              <Typography variant='subtitle2' className='flex items-center' color='#253858'>
                <WarningRounded />
                You have reached your quota cap for this sale
              </Typography>
            </div>
          </div>
        )}

        <Dialog open={isOpenBuy} maxWidth='md'>
          <PopupBuy
            products={products}
            index={chosenIndex}
            onClose={onCloseBuy}
            onSuccess={onOpenCart}
            onRefresh={onRefresh}
          />
        </Dialog>
        <Dialog open={isOpenCart} maxWidth='md'>
          <PopupCart onClose={onCloseCart} onSuccess={onOpenDone} onRefresh={onRefresh} />
        </Dialog>
        <Dialog open={isOpenDone} onClose={onCloseDone} maxWidth='md'>
          <PopupDone />
        </Dialog>

        <div className='h-40' />
      </Container>
    </div>
  );
};

export default McGeeLanding;
