import { Grid, Link as MuiLink, Typography } from '@mui/material';
import { Facebook, Instagram, Language, Twitter } from '@mui/icons-material';
import { formatNumber } from 'utils/common';

const BoomiBanner = () => {
  return (
    <div className='relative box-border sm:p-10'>
      <Grid container spacing={5} columns={11} className='mb-6'>
        <Grid item lg={5} xs={11}>
          <img
            src={require('assets/projects/boomi/banner.png').default}
            className='mx-auto rounded-lg sm:rounded-none'
          />
        </Grid>
        <Grid item lg={6} xs={11}>
          <div className='flex flex-col sm:flex-row items-end justify-between'>
            <img src={require('assets/projects/boomi/logo-primary.webp').default} style={{ height: 54 }} />
            <div className='m-2'>
              <MuiLink
                href='http://superboomi.com'
                className='text-gray-200 hover:text-white mx-1'
                title='Official Website'
              >
                <Language />
              </MuiLink>
              <MuiLink
                href='https://twitter.com/UPStudiosWorld'
                className='text-gray-200 hover:text-white mx-1'
                title='Twitter'
              >
                <Twitter />
              </MuiLink>
              <MuiLink
                href='https://facebook.com/upstudiosworld'
                className='text-gray-200 hover:text-white mx-1'
                title='Facebook'
              >
                <Facebook />
              </MuiLink>
              <MuiLink
                href='https://instagram.com/upstudiosworld'
                className='text-gray-200 hover:text-white mx-1'
                title='Instagram'
              >
                <Instagram />
              </MuiLink>
            </div>
          </div>
          <Typography paragraph color='silver' align='justify'>
            Super BOOMi is an action-adventure series featuring a wondrous virtual reality game universe, and has been a
            breakthrough success as the most popular award-winning animated show in Asia with coding, technology and
            friendship as its core themes.
          </Typography>
          <Typography paragraph color='silver' align='justify'>
            Created by acclaimed artist and entrepreneur Trevor Lai, Super BOOMi is the first cartoon in China to
            feature coding and virtual reality prominently. Since its debut on major TV broadcasters for kids such as
            CCTV and Hunan TV, the series has been ranked amongst the top-3 highest-rated cartoons in the country over
            100 times, making Super BOOMi one of the biggest success stories in the industry in recent years.
          </Typography>
          <Typography paragraph color='silver' align='justify'>
            Super BOOMi’s licensing success is exceptional, with Up Studios partnering with the Intercontinental Hotel
            Group to create BOOMi themed family rooms in over 100 Holiday Inn hotels and resorts. In addition, BOOMi is
            the first ever Family Ambassador for the National Hockey League in China, where ice hockey is gaining
            popularity ahead of the 2022 Beijing Winter Olympics.
          </Typography>
        </Grid>
      </Grid>
      <div className='bg-gray-500' style={{ height: 1 }} />
      <Grid container columns={5}>
        <Grid item md={1} xs={5} className='relative text-center mt-4'>
          <Typography className='mb-0 md:mb-2'>Total Amount</Typography>
          <Typography variant='h3'>{formatNumber(2000)} boxes</Typography>
          <div className='absolute bg-gray-500 hidden md:inline' style={{ width: 1, height: 40, right: 0, top: 12 }} />
        </Grid>
        <Grid item md={1} xs={5} className='relative text-center mt-4'>
          <Typography className='mb-0 md:mb-2'>Network</Typography>
          <Typography variant='h3'>BSC</Typography>
          <div className='absolute bg-gray-500 hidden md:inline' style={{ width: 1, height: 40, right: 0, top: 12 }} />
        </Grid>
        <Grid item md={1} xs={5} className='relative text-center mt-4'>
          <Typography className='mb-0 md:mb-2'>NFT Type</Typography>
          <Typography variant='h3'>BEP-1155</Typography>
          <div className='absolute bg-gray-500 hidden md:inline' style={{ width: 1, height: 40, right: 0, top: 12 }} />
        </Grid>
        <Grid item md={1} xs={5} className='relative text-center mt-4'>
          <Typography className='mb-0 md:mb-2'>NFT Distribution</Typography>
          <Typography variant='h3'>On public sales</Typography>
          <div className='absolute bg-gray-500 hidden md:inline' style={{ width: 1, height: 40, right: 0, top: 12 }} />
        </Grid>
        <Grid item md={1} xs={5} className='text-center mt-4'>
          <Typography className='mb-2'>Currency</Typography>
          <div className='flex justify-center'>
            {['USDT', 'USDC', 'BUSD', 'SPO'].map((coin, index) => (
              <img
                key={index}
                src={require(`assets/icons/coin-${coin}.png`).default}
                className='h-7 mx-0.5'
                title={coin}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default BoomiBanner;
