import { Typography, Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import WizardiaInventory from './WizardiaInventory';
import { styled } from '@mui/system';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '.MuiTabs-indicator': {
    display: 'none',
  },
  [`& .${tabsClasses.root}, .MuiTabs-scroller`]: {
    padding: '24px !important',
    margin: '-24px !important',
  },
  [`& .${tabsClasses.flexContainer}`]: {
    flexWrap: 'wrap'
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: '#E2E4E9',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: '8px',
  border: '1px #383A3E solid',
  borderRadius: '28px',
  position: 'relative',
  background: 'transparent',
  marginBottom: '6px',
  '&.Mui-selected': {
    background: '#7659AE',
    boxShadow: '0px 3px 20px #7000FF',
    ['&:before']: {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
      borderRadius: 'inherit',
      background: 'linear-gradient(to right bottom, #260B3C, #a053df)',
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const WizardiaTabs = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className='w-full'>
      <StyledAppBar position='static' className='p-0'>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='secondary'
          textColor='inherit'
        >
          <StyledTab label='Sale details' {...a11yProps(0)} />
          <StyledTab label='Token Metrics' {...a11yProps(1)} />
          <StyledTab label='Project information' {...a11yProps(2)} />
          <StyledTab label='Restrictions' {...a11yProps(3)} />
        </Tabs>
      </StyledAppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel className='bg-inherit' value={value} index={0} dir={theme.direction}>
          <div className='w-full' style={{ border: '1px #41464E solid', borderWidth: '1px 1px 0px 1px' }}>
            {[
              {
                title: 'Total Raise',
                info: '$30,000',
              },
              {
                title: 'Token price',
                info: '$0.12',
              },
              {
                title: 'KYC',
                info: 'Yes',
              },
              {
                title: 'Start/End:',
                info: '17 March 2022 13:00 - 19 March 2022 14:00 (UTC)',
              },
              {
                title: 'VIP Allocation Round',
                info: '17 March 2022 13:00 (UTC)',
              },
              {
                title: 'VIP Double Quota Round',
                info: '18 March 2022 13:00 (UTC)',
              },
              {
                title: 'FCFS Round (VIP access only)',
                info: '18 March 2022 14:00 (UTC)',
              },
              {
                title: 'Network',
                info: 'IDO and Distribution on BNB Chain',
              },
              {
                title: 'Registration time',
                info: '11 March 2022 05:00  - 16 March 2022 13:00 (UTC)',
              },
            ].map((item, index) => (
              <div
                className='flex justify-between items-end md:text-left px-6 py-4'
                style={{ borderBottom: '1px #41464E solid' }}
              >
                <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                  {item.title}
                </div>
                <div className='font-bold text-right' style={{ fontSize: '18px', color: '#ffffff' }}>
                  {item.info}
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div className='w-full' style={{ border: '1px #41464E solid', borderWidth: '1px 1px 0px 1px' }}>
            {[
              {
                title: 'Token name',
                info: 'Wizardia (WZRD)',
              },
              {
                title: 'Network',
                info: 'BNB Chain',
              },
              {
                title: 'Token price',
                info: '$0.12',
              },
              {
                title: 'Total supply',
                info: '300,000,000 WZRD',
              },
              {
                title: 'Initial Supply',
                info: '8,940,000 WZRD',
              },
              {
                title: 'Market cap',
                info: '$1,072,842',
              },
              {
                title: 'Token listing',
                info: '22 March 2022 11:00 UTC (TBA)',
              },
              {
                title: 'Vesting details',
                info: '20% at TGE month, 5 months vesting.',
              },
            ].map((item, index) => (
              <div
                className='flex justify-between items-end md:text-left px-6 py-4'
                style={{ borderBottom: '1px #41464E solid' }}
              >
                <div className='font-normal' style={{ fontSize: '16px', color: '#A2A5A9' }}>
                  {item.title}
                </div>
                <div className='font-bold text-right' style={{ fontSize: '18px', color: '#ffffff' }}>
                  {item.info}
                </div>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <WizardiaInventory />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <div style={{ color: '#A2A5A9', fontSize: '18px' }} className='py-8'>
            Restricted Countries & Territories:
          </div>
          <div style={{ color: '#A2A5A9', fontSize: '18px' }}>
            Afghanistan , Angola , Azerbaijan , Bahamas , Bosnia and Herzegovina , Botswana , Burundi , Cambodia,
            Cameroon, Canada , Chad , China , Congo (Congo-Brazzaville) , Cuba , Democratic, Republic of the Congo ,
            Eritrea , Ethiopia , Ghana , Guinea , Guinea-Bissau , Haiti , Iran , Iraq , Laos , Libya , Madagascar ,
            Mozambique , Nicaragua , North Korea , Pakistan , Serbia , Seychelles , Somalia , South Sudan , Sri Lanka ,
            Sudan , Syria , Tajikistan , Trinidad and Tobago , Tunisia , Turkmenistan , Uganda , Ukraine , United States
            of America , Uzbekistan , Vanuatu , Venezuela , Yemen , Zimbabwe.
          </div>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};
export default WizardiaTabs;
