import { Link, Paper } from '@mui/material';
import { DesignButton, DesignContainer } from 'components';
import React from 'react';

const AnimaliaGuide = () => {
  return (
    <DesignContainer title='How to participate'>
      <Paper style={{ backgroundColor: '#101010' }} className='text-white p-3 sm:p-6 mb-6'>
        <div className='font-black text-xl mb-2'>1. CONNECT WALLET</div>
        <div>
          On visiting Spores Launchpad, you are required to connect your Metamask wallet on Binance Smart Chain network.
          Please ensure that you have sufficient balance in your wallet before taking part in the sales so you will not
          miss any desired item.
        </div>
      </Paper>

      <Paper style={{ backgroundColor: '#101010' }} className='text-white p-3 sm:p-6 mb-6'>
        <div className='font-black text-xl mb-2'>2. STAKE & WHITELIST</div>
        <div className='mb-4'>
          You can participate in Animalia INO as either a public customer or a VIP customer. Participants must fill out
          the form of interest in order to be whitelisted and qualified for VIP access. Spores will confirm your VIP
          level along with your entry time for the sale after we have reviewed your application. VIP customers can
          access the sales earlier and purchase NFTs with higher quota as well as get the better discount. After all VIP
          tiers have completed their purchase round, public customers can join and make purchases (subject to available
          inventory)
        </div>
        <table className='text-center mb-6' style={{ backgroundColor: '#232324' }}>
          <thead className='font-black'>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>VIP Level</td>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>Requirement</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>VIP 3</td>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>Top 25 SPO holders + top 5 LP stakers</td>
            </tr>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>VIP 2</td>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>Minimum stake 100k SPO</td>
            </tr>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>VIP 1</td>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>Minimum stake 50k SPO</td>
            </tr>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>Public Round</td>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>FCFS</td>
            </tr>
          </tbody>
        </table>
        <Link href='https://spores.xperx.ai/animalia-whitelist'>
          <DesignButton>GET VIP ACCESS</DesignButton>
        </Link>
      </Paper>

      <Paper style={{ backgroundColor: '#101010' }} className='text-white p-3 sm:p-6'>
        <div className='font-black text-xl mb-2'>3. PARTICIPATE</div>
        <div className='mb-2 flex flex-wrap'>
          <img src={require('assets/icons/Bell.svg').default} className='h-6' />
          <span className='font-black'>&nbsp;Animalia INO&nbsp;</span> will happen&nbsp;
          <span className='font-black'>Monday, 03 Jan, 2022, 03:00 AM UTC&nbsp;</span> to&nbsp;
          <span className='font-black'>Tuesday, 04 Jan, 2022, 11:00 AM UTC&nbsp;</span>
          <img src={require('assets/icons/Bell.svg').default} className='h-6' />
        </div>
        <div className='text-justify mb-2'>
          You can participate in <span className='font-black'>Animalia Presale </span> as either a public customer or a
          VIP customer. VIP customers can access the sales earlier. After all VIP tiers have completed their purchase
          round, public customers can join and make purchases (subject to available inventory).
        </div>
        <table className='text-center mb-6' style={{ backgroundColor: '#232324' }}>
          <thead className='font-black'>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>VIP Level</td>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>Entry time (UTC)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>VIP 3</td>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>03:00 AM, 3 Jan 2022</td>
            </tr>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>VIP 2</td>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>03:30 AM, 3 Jan 2022</td>
            </tr>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>VIP 1</td>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>04:00 AM, 3 Jan 2022</td>
            </tr>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>Public Round</td>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>04:30 AM, 3 Jan 2022</td>
            </tr>
          </tbody>
        </table>
        <div className='mb-6'>
          <span className='font-black'>Exclusive Spores x Animalia Discounts</span>
        </div>

        <table className='text-center mb-6' style={{ backgroundColor: '#232324' }}>
          <thead className='font-black'>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>Minimum order</td>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>Discount</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>Purchase 5 NFTs</td>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>3% discount</td>
            </tr>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>Purchase 10 NFTs</td>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>6% discount</td>
            </tr>
            <tr>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>Purchase 15 NFTs</td>
              <td className='border border-gray-700 h-10 w-52 px-1 w-72'>10% discount</td>
            </tr>
          </tbody>
        </table>
      </Paper>
    </DesignContainer>
  );
};

export default AnimaliaGuide;
