import { Add, Remove } from '@mui/icons-material';
import { Button, Dialog, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as Danger } from 'assets/projects/animalia/dangerIcon.svg';
import { ReactComponent as Star } from 'assets/projects/animalia/star.svg';
import { CountdownFlipAnimalia } from 'components';
import { erc20Contract, web3 } from 'contracts/contract';
import { usePopup } from 'hooks';
import { DateTime } from 'luxon';
import React from 'react';
import NumberFormat from 'react-number-format';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { connectWallet } from 'reducers/profileAction';
import { marketService } from 'services/market';
import { storageService } from 'services/storage';
import { CampTimer, checkVipLevelStartTime } from 'utils/campaign';
import { formatNumber } from 'utils/common';
import { PopupCart, PopupDone } from 'views/Checkout';
import { BOOMI_START_TIME } from 'env';

export const ButtonCurrency = styled(Button)(({ theme }) => ({
  background: 'rgba(55, 46, 73, 0.8)',
  '&:hover': {
    background: 'rgba(55, 46, 73, 0.8)',
  },
  height: '44px',
  boxSizing: 'border-box',
  borderRadius: '4px',
  color: '#9994A3',
  fontSize: '14px',
  padding: '8px 39px',
  '&.active': {
    color: '#ffffff',
    boxShadow: '0px 3px 20px #7000FF',
    background: '#7659AE',
    '&:hover': {
      background: '#7659AE',
    },
  },
  [theme.breakpoints.down('xl')]: {
    padding: '8px 25px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '8px 15px',
  },
}));

function AnimaliaLeftCard() {
  const { isLoggedIn, address } = useSelector(({ profile }) => profile);
  const [currency, setCurrency] = React.useState({
    name: 'USDT',
    index: 0,
  });
  const handClickCurrency = (event, index) => {
    setCurrency({ name: event.target.value, index: index });
  };

  //get Quota of user level
  const { data: quota = {}, refetch: refetchQuota } = useQuery(
    ['marketService.getQuota'],
    () => marketService.getQuota(),
    {
      refetchOnMount: 'always',
      enabled: isLoggedIn,
      refetchInterval: 60 * 1000,
      staleTime: 0,
    },
  );
  const vipLevel = quota.name;
  //time countdown
  const [{ isStarted, isFinished, countdown }, setCounDown] = React.useState(CampTimer());
  React.useEffect(() => {
    if (isLoggedIn) {
      setCounDown(CampTimer(checkVipLevelStartTime(vipLevel)));
    } else {
      setCounDown(CampTimer(BOOMI_START_TIME));
    }
  }, [vipLevel, isLoggedIn]);
  //get Product
  const { data = [], refetch: refetchProduct } = useQuery(
    ['marketService.fetchProducts'],
    () => marketService.fetchProducts(),
    {
      refetchOnMount: 'always',
    },
  );

  const products = React.useMemo(() => {
    const prices = data[0]?.product?.price;
    return {
      product_id: data[0]?.product_id,
      prices,
      name: data[0]?.product.name,
      remain_amount: data[0]?.remain_quantity,
    };
  }, [data]);

  //fetch new user level and product
  const onRefresh = () => {
    refetchQuota();
    refetchProduct();
  };

  //amount
  const [amounts, setAmounts] = React.useState(0);
  const [discount, setDiscount] = React.useState(0);
  const setAmount = (value) => {
    setAmounts(value);
    if (value < 2) {
      setDiscount(0);
    } else if (value >= 2 && value < 3) {
      setDiscount(5);
    } else {
      setDiscount(10);
    }
  };
  const totalDiscount = (amounts * products?.prices?.[currency.index]?.price * discount) / 100;
  const totalPrice = amounts * products?.prices?.[currency.index]?.price * (1 - discount / 100);

  //dialog
  const [isOpenCart, onOpenCart, onCloseCart] = usePopup(() => {
    let { createdAt, isContinue = false } = storageService.getOrder();
    if (createdAt) {
      const endTime = DateTime.fromISO(createdAt).plus({ minutes: 15 });
      isContinue = endTime > DateTime.now();
    }
    if (!isContinue) storageService.clearOrder();
    return isContinue;
  });
  const [isOpenDone, onOpenDone, onCloseDone] = usePopup();

  //checkout
  const currentPrice = data[0]?.product?.price[currency.index];
  const { mutate: checkout } = useMutation(marketService.checkout, {
    onSuccess: (data, body) => {
      const { order } = data;
      storageService.saveOrder({
        orderId: order.id,
        createdAt: order.CreatedAt,
        ...body,
        currentPrice,
        discount: discount / 100,
        totalDiscount,
        totalPrice: Number(order.total_amount),
      });

      setTimeout(() => {
        onOpenCart();
        onRefresh();
      }, 0);
    },
  });

  const [isEnoughBalance, setIsEnoughBalance] = React.useState(true);
  const handleClickCheckout = async () => {
    const { payment_token } = currentPrice;
    const balance = payment_token?.address
      ? await erc20Contract(payment_token.address).methods.balanceOf(address).call()
      : await web3.eth.getBalance(address);
    const isEnough = Number(balance) >= totalPrice * 10 ** (payment_token.decimal || 18);
    if (!isEnough) {
      setIsEnoughBalance(false);
      setTimeout(() => {
        setIsEnoughBalance(true);
      }, 5000);
      return;
    }

    checkout({
      products: [
        {
          product_id: products.product_id,
          amount: amounts,
          name: products.name,
        },
      ],
      currency: currency.name,
    });
  };
  const isItemLeft = products.remain_amount === 0;

  return (
    <div>
      <div className='flex space-x-4 mb-4'>
        <img
          src={require('assets/projects/animalia/banner.png').default}
          className='rounded-lg  w-24 h-24 border-white border-8'
        />
        <div className='flex flex-col items-start'>
          <Typography variant='h3' className='text-white'>
            {products.name}
          </Typography>
          {vipLevel && (
            <span
              className='text-white h-8 flex items-center rounded-2xl px-3 text-sm mt-2'
              style={{ background: 'linear-gradient(225deg, #68DBF2 0.01%, #509CF5 100%)', width: 'auto' }}
            >
              <Star className='mr-2' /> {vipLevel}
            </span>
          )}
          <Typography component='p' className=' mt-2' style={{ color: '#A0AEC0' }}>
            USDT / USDC / BUSD
          </Typography>
        </div>
      </div>
      <div
        className='max-w-xl flex flex-col items-center justify-center mb-6'
        style={{ minHeight: '140px', background: 'rgba(55, 46, 73, 0.8)', borderRadius: '8px' }}
      >
        <Typography variant='subtitle1' align='center' className='mb-4 text-sm text-white' letterSpacing={2}>
          {isFinished ? 'Sale has ended' : isStarted ? 'Sale ends in' : 'Sale starts in'}
        </Typography>
        <CountdownFlipAnimalia {...countdown} />
      </div>
      {isStarted && isLoggedIn && !isFinished ? (
        <div className='flex flex-col'>
          <div
            className='flex p-2 h-13 rounded-lg mb-6 items-center'
            style={{ background: 'rgba(55, 46, 73, 0.8)', border: '2px solid #624266' }}
          >
            <img
              src={require('assets/projects/animalia/banner.png').default}
              className=' w-9 h-9 border-black border-2 mr-2.5'
            />
            <Typography
              variant='h4'
              className='flex-grow text-transparent bg-clip-text'
              style={{
                background: '-webkit-linear-gradient(180deg, #FFEF5E 50%,  #F7936F 100%)',
              }}
            >
              {products.name}
            </Typography>
            <Typography component='p' className='text-sm leading-7' style={{ color: '#C0B9CE' }}>
              {!isItemLeft ? (
                `${formatNumber(products.remain_amount)} left`
              ) : (
                <span className='text-red-600 text-sm'>SOLD OUT</span>
              )}
            </Typography>
          </div>
          {!isItemLeft && (
            <>
              <div className='mb-3'>
                <Typography className='mb-1' component='p' style={{ color: '#C0B9CE' }}>
                  Select currency
                </Typography>
                <div className='lg:space-x-1 space-x-4  flex justify-center'>
                  {products?.prices?.map((item, index) => (
                    <ButtonCurrency
                      className={item.payment_token.name === currency.name ? 'active' : ''}
                      value={item.payment_token.name}
                      onClick={(e) => handClickCurrency(e, index)}
                    >
                      {item.payment_token.name}
                    </ButtonCurrency>
                  ))}
                </div>
              </div>
              <div
                style={{ minHeight: '116px', background: 'rgba(55, 46, 73, 0.8)', borderRadius: '8px' }}
                className='flex flex-col p-4 justify-center mb-3 text-white'
              >
                <div className='flex justify-between mb-4.5 mb-2'>
                  <span style={{ color: '#C0B9CE' }}>Price</span>
                  <span>
                    {parseInt(products?.prices?.[currency.index]?.price).toFixed(0)} {currency.name}
                  </span>
                </div>
                <div className='flex justify-between  items-center text-white'>
                  <span style={{ color: '#C0B9CE' }}>Quantity</span>
                  <div className='flex items-center rounded p-1' style={{ backgroundColor: '#201831' }}>
                    <IconButton size='small' className='mx-1' onClick={() => setAmount(Math.max(0, amounts - 1))}>
                      <Remove className='text-white' />
                    </IconButton>
                    <div className='box-border rounded font-medium p-1' style={{ borderColor: '#5E6484' }}>
                      <NumberFormat
                        value={amounts}
                        onValueChange={({ floatValue: value }) => setAmount(value)}
                        onBlur={() => {
                          if (!amounts) setAmount(0);
                          if (amounts > products.remain_amount) setAmount(products.remain_amount);
                        }}
                        maxLength={3}
                        allowNegative={false}
                        className='w-16 text-center bg-transparent'
                      />
                    </div>
                    <IconButton
                      size='small'
                      className='mx-1'
                      onClick={() => setAmount(Math.min(products.remain_amount, amounts + 1))}
                    >
                      <Add className='text-white' />
                    </IconButton>
                  </div>
                </div>
              </div>
              <div
                className='flex flex-col p-4 mb-3 justify-center text-white'
                style={{ minHeight: '132px', background: 'rgba(55, 46, 73, 0.8)', borderRadius: '8px' }}
              >
                <div className='flex justify-between mb-4.5 mb-2'>
                  <span style={{ color: '#C0B9CE' }}>Subtotal</span>
                  <span>
                    {amounts ? formatNumber(products?.prices?.[currency.index]?.price * amounts) : 0} {currency.name}
                  </span>
                </div>
                <div className='flex justify-between mb-4.5 mb-2'>
                  <span style={{ color: '#C0B9CE' }}>Discount</span>
                  <span>
                    {totalDiscount !== 0 && amounts && '-'}
                    {`${amounts ? formatNumber(totalDiscount) : 0} ${currency.name}`}
                  </span>
                </div>
                <div className='flex justify-between mb-4.5 '>
                  <span
                    style={{
                      color: '#C0B9CE',
                    }}
                  >
                    Price
                  </span>
                  <span
                    className='bg-clip-text text-transparent font-semibold text-lg'
                    style={{
                      background: '-webkit-linear-gradient(90deg, #F7936F 10%, #FFEF5E 70%, #FFEF5E 70%)',
                    }}
                  >
                    {`${amounts ? formatNumber(totalPrice) : 0} ${currency.name}`}
                  </span>
                </div>
              </div>
              {!isEnoughBalance && (
                <div className='flex justify-center mb-4 mt-1'>
                  <Typography className='flex' style={{ color: '#FF4841' }} component='p'>
                    <Danger className='pr-1' /> Insufficient balance
                  </Typography>
                </div>
              )}
              <Button
                disabled={!isEnoughBalance}
                className='h-14 text-base font-semibold'
                onClick={handleClickCheckout}
              >
                CHECK OUT
              </Button>
            </>
          )}
        </div>
      ) : (
        <div className='flex flex-col items-center mt-4'>
          {!isLoggedIn ? (
            <Button className='w-18' onClick={() => connectWallet()}>
              Connect wallet
            </Button>
          ) : isFinished ? (
            <p className='text-lg font-bold' style={{ color: '#E4ECF7' }}>
              SALE HAS ENDED
            </p>
          ) : (
            <p
              className='text-lg font-bold bg-clip-text text-transparent'
              style={{
                background:
                  '-webkit-linear-gradient(90deg, rgba(255,130,130,1) 0%, rgba(255,196,79,1) 70%, rgba(255,81,0,1) 100%)',
                // '-webkit-background-clip': 'text',
                // '-webkit-text-fill-color': 'transparent',
              }}
            >
              COMING SOON
            </p>
          )}
        </div>
      )}
      <Dialog open={isOpenCart} maxWidth='md'>
        <PopupCart onClose={onCloseCart} onSuccess={onOpenDone} onRefresh={onRefresh} />
      </Dialog>
      <Dialog open={isOpenDone} onClose={onCloseDone} maxWidth='md'>
        <PopupDone />
      </Dialog>
    </div>
  );
}

export default AnimaliaLeftCard;
